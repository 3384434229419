.devider {
  width: 100%;
  height: 1px;
  background: #c1c1c1;
  margin: 20px 0px;
}
.sortBy {
  display: flex;
  margin: 20px 0px;
}
.sortByOff {
  opacity: 1;
  color: rgba(0, 0, 0, 1);
  font-family: Manrope;
  font-size: 13px;
  font-weight: 500;
  letter-spacing: 0.26px;
  margin: 0px 0px 0px 4px;
  cursor: pointer;
}
.sortByOn {
  opacity: 1;
  color: rgba(0, 0, 0, 1);
  font-family: Manrope;
  font-size: 13px;
  font-weight: 700;
  letter-spacing: 0.26px;
  margin: 0px 0px 0px 4px;
  cursor: pointer;
}
.eventListBox {
  min-height: 120px;
  width: 100%;
  border-radius: 4px;
  background-color: #ffffff;
  border: 1px solid #cbcbcb;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 5px 15px;
  margin-bottom: 10px;
}
.imageSection {
  width: 100px;
  height: 100px;
  border-radius: 4px;
  object-fit: cover;
  margin-right: 15px;
  cursor: pointer;
  background-color: #404040;
}
.detail {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.detail p {
  opacity: 1;
  color: rgba(0, 0, 0, 1);
  font-family: Manrope;
  font-size: 15px;
  font-weight: 500;
  letter-spacing: 0.3px;
  margin: 0;
  word-break: break-word;
}
.detail h1 {
  opacity: 1;
  color: rgba(0, 0, 0, 1);
  font-family: Manrope;
  font-size: 15px;
  font-weight: 700;
  letter-spacing: 0.3px;
  word-break: break-all;
}
.detail span {
  opacity: 1;
  color: rgba(0, 0, 0, 1);
  font-family: Manrope;
  font-size: 15px;
  font-weight: 400;
  letter-spacing: 0.3px;
  margin: 0;
}
.detail h2 {
  opacity: 1;
  color: rgba(255, 67, 0, 1);
  font-family: Manrope;
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 0.24px;
  margin: 0;
}

.editCross {
  height: 58px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-left: 5px;
}
.editCross img {
  width: 24px;
  height: 24px;
}

.inviteormore {
  width: 100%;
  height: 60px;
  background: #ffffff;
  margin-top: 25px;
  padding: 10px;
  display: flex;
  justify-content: space-between;
  border: 1px solid #b5b5b5;
  border-radius: 5px;
  align-items: center;
}
.membersCount {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.membersCount p {
  font-family: Manrope;
  font-size: 14px;
  font-weight: 700;
  color: #ff4300;
  margin: 0;
}
.membersCount span {
  font-family: Manrope;
  font-size: 14px;
  font-weight: 700;
  color: #000000;
  margin: 0;
}

.noDataFound p {
  font-family: Manrope;
  font-weight: 700;
  font-size: 15px;
  line-height: 23px;
  color: #212529;
  margin: 0;
}
.noDataFound span {
  font-family: Manrope;
  font-weight: 500;
  font-size: 15px;
  line-height: 23px;
  color: #212529;
  margin: 0;
}

.noActiveGrp P {
  opacity: 1;
  color: rgba(33, 37, 41, 1);
  font-family: Manrope;
  font-size: 16px;
  font-weight: 500;
  /* line-height: 46px; */
  margin: 0px 0px 15px 0px;
}
.noActiveGrp span {
  opacity: 1;
  color: #ff4300;
  font-family: Manrope;
  font-size: 16px;
  font-weight: 500;
  line-height: 23px;
  margin: 0;
}

.activeIcon {
  width: 24px;
  height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgb(7, 124, 7);
  border-radius: 4px;
  cursor: pointer;
}
