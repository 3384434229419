/* CustomTabButton.module.css  */
.CustomTabButton {
  border-radius: 18px;
  padding: 0px 20px;
  font-family: "Manrope";
  font-size: 14.4px;
  font-weight: 500;
  margin-right: 10px;
  height: 35px;
}

.primaryCustom {
  background-color: #000000 !important;
  color: white !important;
  border: none !important;
}

.primaryCustom:hover {
  background-color: #383737 !important;
}

.secondaryCustom {
  background-color: white !important;
  color: black !important;
  border: none !important;
}

.secondaryCustom:hover {
  background-color: #dbdbdb !important;
}

/* Add more classes for other variant colors if needed */
.imageButton {
  display: inline-flex;
  align-items: center;
  border: none !important;
  background: none !important;
  padding: 0;
  cursor: pointer;
}

.imageButton img {
  display: block;
  width: 100%;
  height: auto;
  transition: transform 0.2s;
}

.imageButton img:hover {
  transform: scale(1.05);
}

.footerBtnCustom {
  align-items: center;
  background-color: #ff4300 !important;
  border: 1px solid #ff4300;
  border-radius: 4px;
  display: flex;
  height: 35px;
  justify-content: center;
  /* max-width: 151px;
  min-width: 100px; */
  padding: 0 15px;
}

.footerBtnCustom:hover {
  border: 1px solid #fa6833 !important;
  background-color: #fa6833 !important;
}

.footerBtnCustom:active {
  /* border: none !important; */
  border: 1px solid #fa6833 !important;
}

.footerBtnCustom p {
  font-weight: 400 !important;
  font-size: 14px !important;
  margin: 0 !important;
  color: #ffffff !important;
}

.footerBtnCustom span {
  font-weight: 700 !important;
  margin: 0 !important;
  color: #ffffff !important;
}

.cancelBtn {
  background-color: #ffffff !important;
  border-radius: 4px;
  border: 1px solid #000000 !important;
  color: #000000 !important;
  font-size: 14px;
  font-weight: 500;
  margin-right: 10px;
  height: 34px;
}

.cancelBtn:hover {
  color: #000000 !important;
  border: 1px solid #000000 !important;
  background-color: #ffffff !important;
}

.exBrandingBtn {
  background-color: #ffffff !important;
  border-radius: 4px;
  border: 1px solid #ffffff !important;
}

.exBrandingBtn:hover {
  border: 1px solid #ffffff !important;
  background-color: #ffffff !important;
}

.exBrandingBtn p {
  font-weight: 900 !important;
  font-size: 17px !important;
  margin: 0 !important;
  /* color: #ff4300 ; */
}

.exBrandingBtn span {
  font-weight: 300 !important;
  margin: 0 !important;
  /* color: #ff4300 ; */
}

.exBrandingBtn:active {
  border: none !important;
}

.footerBtnCustomBranding {
  border-radius: 4px;
  border: none !important;
}

.footerBtnCustomBranding:hover {
  border: none !important;
}

.footerBtnCustomBranding:active {
  border: none !important;
}

.footerBtnCustomBranding p {
  font-weight: 400 !important;
  font-size: 14px !important;
  margin: 0 !important;
}

.footerBtnCustomBranding span {
  font-weight: 700 !important;
  margin: 0 !important;
}


/* gallary */

.footerBtnCustomGallry {
  align-items: center;
  background-color: #ff4300 !important;
  border: 1px solid #ff4300;
  border-radius: 4px;
  display: flex;
  height: 35px;
  justify-content: center;
  max-width: 151px;
  min-width: 100px;
  padding: 0 15px;
}

.footerBtnCustomGallry:hover {
  border: 1px solid #fa6833 !important;
  background-color: #fa6833 !important;
}

.footerBtnCustomGallry:active {
  /* border: none !important; */
  border: 1px solid #fa6833 !important;
}

.footerBtnCustomGallry p {
  font-weight: 400 !important;
  font-size: 14px !important;
  margin: 0 !important;
  color: #ffffff !important;
}

.footerBtnCustomGallry span {
  font-weight: 700 !important;
  margin: 0 !important;
  color: #ffffff !important;
}