.topHead {
  display: flex;
}

.buttonSection {
  display: flex;
  padding-bottom: 15px;
  border-bottom: 1px solid #c1c1c1;
}

/* .distance {
  width: 110px;
  height: 40px;
  background: #000000;
  border-radius: 20px;
  border: 1px solid #000000;
  font-size: 14.4px;
  font-weight: 500;
  color: #ffffff;
} */

.heading {
  font-size: 20px;
  font-weight: 600;
  letter-spacing: 0.46px;
  color: #000000;
  margin: 0;
  text-transform: uppercase;
}

.pbDis {
  width: 110px;
  height: 40px;
  background: #ffffff;
  border-radius: 20px;
  border: 1px solid #ffffff;
  font-size: 14.4px;
  font-weight: 500;
  color: #000000;
}

.devider {
  width: 100%;
  height: 1px;
  background: #c1c1c1;
  margin: 15px 0px;
}

.distanceShow {
  margin-top: 15px;
  border: 1.5px solid #c1c1c1;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px 0px 8px 15px;
  min-height: 38px;
  word-break: break-all;
  border-radius: 5px;
  background-color: #fff;
}

.editCross img {
  width: 24px;
  height: 24px;
  margin-right: 11px;
}

.distanceBtn {
  background: #ffffff;
  margin-top: 15px;
  padding: 15px 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 1px solid #b5b5b5;
  border-radius: 5px;
  align-items: center;
}

.disRange p {
  color: #ff4300;
  font-size: 13px;
  font-weight: 700;
  margin-bottom: 0px;
  line-height: 24px;
}

.distanceCount {
  display: flex;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.distanceCount p {
  font-size: 14px;
  font-weight: 600;
  color: #ff4300;
  margin: 0;
}
.distanceCount span {
  font-size: 14px;
  font-weight: 600;
  color: #000000;
  margin: 0;
}

.distanceCount button {
  width: 140px;
  height: 40px;
  background: #ff4300;
  border-radius: 5px;
  border: 1px solid #ff4300;
}

.distanceCount button p {
  font-size: 14px;
  font-weight: 400;
  color: #ffffff;
}

.distanceCount button span {
  font-size: 14px;
  font-weight: 700;
  color: #ffffff;
}

.addDistanceSection {
  border: 1px solid #c1c1c1;
  border-radius: 4px;
  margin-top:15px;
}

.addDistanceBox {
  padding: 15px 0px 15px 15px;
  background-color: #ececec;
  border-bottom: 1px solid #c1c1c1;
}

.addDistanceBox p {
  font-size: 16px;
  font-weight: 400;
  color: #394858;
  margin-bottom: 0px;
}

.addDistanceBox span {
  font-size: 16px;
  font-weight: 700;
  color: #394858;
}
.miles {
  display: flex;
  justify-content: space-between;
  padding: 15px 65px 15px 15px;
  border-bottom: 1px solid #c1c1c1;
}

.milesInput {
  display: flex;
}

.inputAreaOne input {
  width: 69px;
  height: 38px;
  background-color: #ececec;
  border: 1px solid #ececec;
  margin-right: 15px;
}

.inputAreaOne input:focus-visible {
  outline: none;
  box-shadow: none;
  border: 1px solid #ececec;
}

.inputArea input {
  width: 36px;
  height: 38px;
  background-color: #ececec;
  border: 1px solid #ececec;
  margin-right: 12px;
}

.inputArea input:focus-visible {
  outline: none;
  box-shadow: none;
  border: 1px solid #ececec;
}
.milesInput span {
  color: #000;
  font-weight: 900;
  font-size: 20px;
}
.miles h5 {
  font-size: 16px;
  font-weight: 600;
  color: #000000;
  margin-bottom: 0px;
  margin-top: 8px;
}
.milesInput label {
  color: #394858;
  font-size: 20px;
  font-weight: 500;
  margin-top: 4px;
}

.milesKm {
  display: flex;
  padding: 15px 75px 15px 10px;
  justify-content: space-between;
}

.milesText p {
  color: #000000;
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 0px;
}
.milesText h5 {
  color: #ff4300;
  font-size: 14px;
  font-weight: 400;
}

.addBottomBtn {
  background-color: #ececec;
  padding: 15px 10px 13px 10px;
}
.btnSection {
  display: flex;
  justify-content: space-between;
}

.btnSection .addDistanceBtn {
  width: 131px;
  height: 34px;
  border: 1px solid #ececec;
  background-color: #ff4300;
  border-radius: 4px;
}

.btnSection .addDistanceBtn p {
  color: #fff;
  font-size: 14px;
  font-weight: 400;
  margin-bottom: 0px;
}

.btnSection .addDistanceBtn span {
  color: #fff;
  font-size: 14px;
  font-weight: 700;
}

/* radio button css */

.distanceSection {
  display: flex;
  flex-direction: column;
  background-color: #fff;
  border-bottom: 1px solid #c1c1c1;
  padding: 10px 0px 10px 0px;
}

.distanceSection h3 {
  font-family: Manrope;
  font-weight: 700;
  font-size: 16px;
  color: #000000;
  margin-right: 10px;
  margin-bottom: 0;
}
.distanceData {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  height: 50px;
}

.distance h6 {
  font-family: Manrope;
  font-weight: 500;
  font-size: 20px;
  color: #000000;
  margin: 0;
}

.timeInput {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.timeInput input {
  width: 65px;
  height: 38px;
  border-radius: 4px;
  border: 1px solid #c4c4c4;
  background: #F6F6F6;
  text-align: center;
}

.timeInput input:focus {
  border: 1px solid #c1c1c1;
  box-shadow: none;
  outline: none;
}

.timeInputMin {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.timeInputMin input {
  width:39px;
  height: 38px;
  border-radius: 4px;
  border: 1px solid #c4c4c4;
  background: #F6F6F6;
  text-align: center;
}

.timeInputMin input:focus {
  border: 1px solid #c1c1c1;
  box-shadow: none;
  outline: none;
}

.dot {
  display: inline-block;
  margin: 0;
  font-size: 50px !important;
  vertical-align: middle;
  color: #000000 !important;
  margin-bottom: 20px;
  padding-bottom: 5px;
}

.distance {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 10px;
  gap: 6px;
  margin-left: -30px;
}

.errorMessage {
  width: 100%;
  margin-left:30px;
  font-size: 0.875em;
  color: red;
}

.noWork {
  margin: auto;
  margin-top: 10px;
  color: #ff4300;
  text-align: center;
}
