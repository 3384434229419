.formSection {
  margin: 0px auto;
  width: 100%;
  max-width: 360px;
}

.bgSection {
  background-color: #424242;
  height: 100vh;
  overflow-y: scroll;
}

.logoImg img {
  width: 100%;
  max-width: 360px;
  margin-top: 130px;
}

.loginForm p {
  color: #fff;
  font-size: 20px;
  font-weight: 900;
  text-align: center;
  margin-top: 40px;
  margin-bottom: 35px;
  letter-spacing: 3px;
  line-height: 20px;
}

.loginForm span {
  color: #ffffffa3;
  font-size: 20px;
  font-weight: 300;
  text-align: center;
  margin-top: 20px;
  margin-bottom: 20px;
}

/*--- input icon css ---------*/
.inputIcon {
  position: relative;
  margin-bottom: 22px;
}

.Icon {
  color: #958b8b;
  top: 47px;
  left: 10px;
  font-size: 18px;
  position: absolute;
  cursor: pointer;
}

.Icon svg {
  font-size: 22px;
  position: absolute;
  bottom: 11px;
  color: #727272;
  cursor: pointer;
}

/*---------- form input css ----------*/
.loginForm .inputIcon .FormInput,
.loginForm .inputIcon select {
  /* padding: 0px 10px;
  height: 48px;
  font-size: 15px;
  width: 100%;
  border-radius: 4px;
  background-color: #fff !important; */
  color: #000 !important;
  font-weight: 400;
  line-height: 1.6 !important;
  touch-action: manipulation;
}

.inputIcon input {
  width: 100%;
  height: 48px;
  border: 1px solid #c4c4c4;
  border-radius: 4px;
  background: #f5f5f5;
  padding-left: 50px;
}

.loginForm .inputIcon .FormInput,
.loginForm .inputIcon select:focus {
  outline: none;
  border: none;
  box-shadow: none;
}

.loginForm .inputIcon .FormInput,
.loginForm .inputIcon ::placeholder {
  color: #727272;
  font-size: 15px;
  font-weight: 500;
}

/*-------login  button css-------- */
.loginBtn button {
  text-align: center;
  margin-top: 25px;
  padding: 14px 10px;
  background-color: #ff4300;
  border-radius: 7px;
  width: 100%;
  color: #fff;
  border: 1px solid #ff4300;
  font-size: 16px;
  font-weight: 500;
}

/*------- pasword lock icon css-----------  */
.lockIcon {
  color: #958b8b;
  top: 47px;
  font-size: 18px;
  position: absolute;
  cursor: pointer;
}

.lockIcon svg {
  font-size: 25px;
  position: absolute;
  bottom: 11px;
  color: #727272;
  cursor: pointer;
  left: 318px;
}

.heading h2 {
  color: #fff;
  font-size: 25px;
  font-weight: 600;
  margin-top: 30px;
  text-align: center;
}
@media screen and (max-width: 340px) {
  .lockIcon svg {
    left: 270px;
  }
}

@media screen and (min-width: 341px) and (max-width: 360px) {
  .lockIcon svg {
    left: 290px;
  }
}
