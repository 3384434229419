.showDay {
  width: 125px;
  height: 50px;
  border: 1px solid #ff4300;
  border-radius: 4px;
  background-color: #ff4300;
  color: #ffffff;
  font-size: 20px;
  font-weight: 700;
  /* font-family: Poppins; */
}
.modalBtnSection {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
}

.previousOrNext {
  height: 50px;
  width: 81px;
  border: 1px solid #eeeeee;
  border-radius: 4px;
  background-color: #eeeeee;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0px 17px;
}
.previousOrNext img {
  width: 28px;
  height: 28px;
  margin-right: 6px;
  margin-left: 0px;
}

@media only screen and (max-width: 991px) {
  .previousOrNext {
    height: 45px;
    width: 72px;
    border: 1px solid #eeeeee;
    border-radius: 4px;
    background-color: #eeeeee;
  }
  .showDay {
    width: 112px;
    height: 45px;
    border: 1px solid #ff4300;
    border-radius: 4px;
    background-color: #ff4300;
    color: #ffffff;
    font-size: 20px;
    font-weight: 700;
    font-family: Manrope;
  }
  .timeAndPace {
    flex-direction: column;
    margin-top: 10px;
  }
  .pace {
    margin-left: 0px;
    margin-top: -15px;
  }
}

/* disable privious clcik in calender */

.previousOrNext.disabled {
  opacity: 0.5;
  cursor: not-allowed;
  pointer-events: none;
}
