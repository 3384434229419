.mainHeading {
  font-weight: 700;
  font-size: 40px;
  color: #262626;
  text-align: center;
  font-family: "Inter", sans-serif;
  padding-top: 50px;
  padding-top: 200px;
  line-height: 1.5;
}
.subHeading {
  font-weight: 400;
  font-size: 30px;
  color: red;
  text-align: center;
  line-height: 1;
  font-family: "Inter", sans-serif;
}
.text404 {
  color: #262626;
  font-size: larger;
  text-align: center;
  line-height: 2;
  font-family: "Inter", sans-serif;
}
.goToHome {
  font-family: "Inter", sans-serif;
  font-weight: 600;
}
.toHome {
  text-align: center;
}

.main {
  width: 100%;
  max-width: 375px;
  margin: 0px auto;
}
