.errorMsg {
  color: red !important;
  font-size: 15px !important;
}

/*---------- Sidebar css----------- */
.NavBody .navbar-toggler {
  padding: var(--bs-navbar-toggler-padding-y) var(--bs-navbar-toggler-padding-x);
  font-size: var(--bs-navbar-toggler-font-size);
  line-height: 1;
  color: var(--bs-navbar-color);
  background-color: black !important;
  border: var(--bs-border-width) solid var(--bs-navbar-toggler-border-color);
  border-radius: var(--bs-navbar-toggler-border-radius);
  transition: var(--bs-navbar-toggler-transition);
}
nav.NavBody {
  padding: 0px;
}
.offcanvas-header {
  display: flex;
  align-items: center;
  padding: 0px;
}
.offcanvas-body {
  background: #000000;
  flex-grow: 1;
  padding: 0px;
  overflow-y: auto;
  width: 260px;
  scrollbar-width: none;
}

a.SideBar_Navlink__rHJNu.active {
  background-color: #fff;
  color: #000;
  text-decoration: none;
  font-size: 14px;
  line-height: 22px;
  font-weight: 500;
}

a.SideBar_Navlink__rHJNu.active svg {
  background-color: #fff;
  color: #000;
  text-decoration: none;
  font-size: 14px;
  line-height: 22px;
  font-weight: 500;
}

.offcanvas-header .btn-close {
  display: none;
  color: white;
  padding: calc(var(--bs-offcanvas-padding-y) * 0.5)
    calc(var(--bs-offcanvas-padding-x) * 0.5);
  margin: calc(-0.5 * var(--bs-offcanvas-padding-y))
    calc(-0.5 * var(--bs-offcanvas-padding-x))
    calc(-0.5 * var(--bs-offcanvas-padding-y)) auto;
}

@media only screen and (max-width: 991px) {
  .MainBody {
    display: block;
  }
  .NavBody .navbar-toggler {
    position: absolute;
    margin-top: 14px;
    padding: var(--bs-navbar-toggler-padding-y)
      var(--bs-navbar-toggler-padding-x);
    font-size: var(--bs-navbar-toggler-font-size);
    line-height: 1;
    color: var(--bs-navbar-color);
    background-color: black !important;
    border: var(--bs-border-width) solid var(--bs-navbar-toggler-border-color);
    border-radius: var(--bs-navbar-toggler-border-radius);
    transition: var(--bs-navbar-toggler-transition);
    left: -30px;
  }
}

.NavBody .navbar-toggler .navbar-toggler-icon {
  margin-top: -10px;
}

/*------ login remeber checkbox css--------- */
/* .remeberChek {
  margin-top: -9px;
} */
.remeberChek .form-check label {
  font-size: 12px;
  font-weight: 400;
  color: #ffffff;
}

.remeberChek .form-check-input {
  background-color: #424242;
  border-color: #fff;
}

.remeberChek .form-check-input:checked {
  background-color: #424242;
  border-color: #fff;
}

.remeberChek .form-check-input:focus {
  border-color: #fff;
  outline: none;
  box-shadow: none;
}

/*--------- brands module tab css------- */
ul.tabSection.nav.nav-tabs {
  border: 2px solid #f6f6f6 !important;
  border-radius: 48px !important;
  margin: 12px 0px 12px;
  position: relative;
}

.tabSection.nav-tabs .nav-link {
  background: #fff !important;
  font-size: 14.5px !important;
  font-weight: 500 !important;
  letter-spacing: 0em;
  color: #ffffff !important;
  border-radius: 48px;
  width: 100%;
}

.tabSection.nav-tabs .nav-link {
  background: #fff !important;
  color: #000000 !important;
  border-top: none !important;
  border-left: none !important;
  border-right: none !important;
  width: 100%;
}

.tabSection li.nav-item {
  margin-right: 10px;
  /* margin-bottom: 7px; */
}

.tabSection.nav-tabs .nav-link:hover {
  border-color: transparent !important;
}

.tabSection.nav-tabs .nav-link.active {
  background: #000 !important;
  font-size: 14.5px !important;
  font-weight: 500 !important;
  letter-spacing: 0em;
  color: #ffffff !important;
  border-radius: 48px;
  width: 100%;
  margin: 2px;
}

.editorBox .quill {
  /* margin-bottom: 20px; */
  margin-bottom: 6px;
}

/*----------- new css custom phone input in brand info---------  */

.phoneInputSet.watsappInput .react-tel-input .country-list {
  z-index: 9999 !important;
}

.phoneInputSet .react-tel-input .flag-dropdown.open {
  z-index: 9;
}

.phoneInputSet.watsappInput .react-tel-input .flag-dropdown.open {
  z-index: 1;
}

.phoneInputSet {
  position: relative;
}

.phoneInputSet svg {
  position: absolute;
  top: 4px;
  left: 76px;
  font-size: 40px;
  z-index: 99;
  color: #000000b8;
  font-weight: 500;
}

.phoneCountry {
  position: relative;
}

.phoneInputSet p {
  font-size: 15px !important;
  z-index: 99;
  color: #000;
}

/* .phoneCountry span {
  padding-left: 3px;
  font-size: 15px;
  z-index:999;
  color: #000;
  margin-top: -3px;
  pointer-events: none !important;
} */

.phoneInputSet .downArrow {
  position: absolute;
  bottom: 6px;
  left: 82px;
  font-size: 28px;
  z-index: 999;
  color: #000;
}

.customPhoneDropDown {
  position: absolute;
  top: -3px;
  left: 3px;
  width: 111px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.customPhoneDropDown p {
  margin-top: 12px !important;
  pointer-events: none !important;
  z-index: 999;
  color: #000;
}

.customPhoneDropDown span {
  margin-top:-5px !important;
  pointer-events: none !important;
  z-index: 999;
  color: #000; 
}


.phoneInputSet .form-control {
  position: relative;
  letter-spacing: 0.01rem;
  margin-top: 0 !important;
  margin-bottom: 0 !important;
  padding-left: 48px;
  margin-left: 0;
  background: #ffffff;
  border: none !important;
  border-radius: 4px;
  line-height: 25px;
  height: 38px !important;
  width: 100% !important;
  outline: none;
  padding-left: 120px !important;
  font-weight: 500;
  font-size: 16.94px !important;
  border: 1px solid #c1c1c1 !important;
}

.phoneInputSet .form-control:focus {
  outline: none !important;
  box-shadow: none !important;
}

.phoneInputSet .react-tel-input .flag-dropdown {
  border: none !important;
  background: #000000 !important;
  width: 110px !important;
  border-top-left-radius: 7.26px !important;
  border-bottom-left-radius: 7.26px !important;
}

.phoneInputSet .react-tel-input .selected-flag .flag {
  display: block !important;
  /* opacity: 0; */
  z-index: 0 !important;
  position: absolute !important;
  top: 14px !important;
  margin-top: 0px !important;
  left: 5px;
}

.react-tel-input .selected-flag .arrow {
  position: relative;
  top: 50%;
  margin-top: -2px;
  left: 20px;
  width: 0;
  height: 0;
  border-left: 3px solid transparent;
  border-right: 3px solid transparent;
  border-top: 4px solid #555;
  opacity: 0;
}

.phoneInputSet .react-tel-input .selected-flag {
  outline: none;
  position: relative;
  width: 115px !important;
  height: 100%;
  padding: 0 0 0 8px;
  background: #e9e9e9 !important;
  border: 1px solid #c1c1c1;
}

.phoneInputSet .react-tel-input .selected-flag {
  border-top-left-radius: 4px !important;
  border-bottom-left-radius: 4px !important;
}

.phoneInputSet:before {
  content: "";
  border-top: 6px solid;
  border-right: 6px solid transparent;
  border-bottom: 0;
  border-left: 6px solid transparent;
  position: absolute;
  left: 90px;
  top: 18px;
  z-index: 999;
  color: #000 !important;
  display: none;
}

/* ---------watsapp input css ------------*/

.watsappInput .react-tel-input .selected-flag {
  background: #e9e9e9 !important;
}

.watsappInput .react-tel-input .selected-flag:hover,
.watsappInput .react-tel-input .selected-flag:focus {
  background: #e9e9e9 !important;
}

.watsappInput .react-tel-input .selected-flag {
  background: #e9e9e9 !important;
}

.invitePhone .form-control:focus {
  box-shadow: none;
  outline: none;
  border: 1px solid #c1c1c1;
  background-color: #f5f5f5;
}

/*-------- whatsapp input css ------------*/
.phoneInputSet.watsappInput .react-tel-input .country-list {
  z-index: 9999 !important;
}

.phoneInputSet.watsappInput .react-tel-input .flag-dropdown.open {
  z-index: 1;
}

.phoneInputSet.watsappInput svg {
  z-index: 2 !important;
}

.phoneInputSet.watsappInput p {
  z-index: 2 !important;
}

.phoneInputSet.watsappInput span {
  z-index: 2 !important;
}

.phoneInputSet.watsappInput::before {
  z-index: 2 !important;
}

/* .watsappInput {
  margin-top: 16px;
} */

.watsappInput .react-tel-input .selected-flag {
  background: #e9e9e9 !important;
}

.watsappInput .react-tel-input .selected-flag:hover,
.watsappInput .react-tel-input .selected-flag:focus {
  background: #e9e9e9 !important;
}

.watsappInput .react-tel-input .selected-flag {
  background: #e9e9e9 !important;
}

/* new css custom phone input in brand info end  */

/*--------- Country selection input css------------ */
.countrySelcet .css-b62m3t-container {
  width: 100% !important;
  max-width: 350px;
  margin-bottom: 10px;
}

.countrySelcet .css-b62m3t-container:focus {
  outline: none !important;
  box-shadow: none !important;
  border: 1px solid #fff !important;
}

/* .countrySelcet input {
  width: 100%;
  max-width: 350px;
  height: 40px;
} */

.countrySelcet input {
  width: 100%;
  max-width: 278px;
  height: 40px;
}

.countrySelcet input:focus {
  outline: none;
  box-shadow: none;
  border: 1px solid #c1c1c1;
}

.countrySelcet .css-13cymwt-control {
  background-color: #ececec !important;
  background-image: linear-gradient(180deg, #ffffff 0%, #ececec 100%);
  opacity: 1;
}

.countrySelcet .css-1prs2ur-Placeholder {
  font-size: 15px;
  font-weight: 500;
  color: #6c757d !important;
  position: absolute;
  top: 12px;
  left: 12px;
}

.countrySelcet .css-1u9des2-indicatorSeparator {
  display: none !important;
}

.countrySelcet .css-tj5bde-Svg {
  color: #424242 !important;
}

.phycicalChek .form-check label {
  font-size: 16px;
  font-weight: 600;
  color: #394858;
}

.phycicalChek .form-check-input {
  background-color: #f5f4f4;
  border-color: #c1c1c1 !important;
}

.phycicalChek .form-check-input:checked {
  background-color: #ff4300;
  border-color: #fff;
}

.phycicalChek .form-check-input:focus {
  border-color: #fff;
  outline: none;
  box-shadow: none;
}

/*----------- Text editor css---------- */

.quill.brandTextEditor .ql-container.ql-snow .ql-editor p {
  color: #000;
  font-family: Manrope;
  font-size: 15px !important;
  font-weight: 400;
  line-height: 24px;
}

.ql-toolbar.ql-snow {
  border: 1px solid #ced4da;
  box-sizing: border-box;
  font-family: "Helvetica Neue", "Helvetica", "Arial", sans-serif;
  padding: 8px;
  background: linear-gradient(180deg, #eee 0%, #d8d8d8 100%);
}
.ql-toolbar.ql-snow .ql-formats {
  margin-right: 0px !important;
}
.ql-toolbar.ql-snow span button {
  border-radius: 4px;
  border: 1px solid #c8c8c8;
  margin-right: 10px;
}
.ql-editor.ql-blank {
  height: 110px;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 38px;
  background-color: #ffffff;
}
.ql-toolbar.ql-snow + .ql-container.ql-snow {
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
}

.quill .ql-container.ql-snow .ql-editor p {
  font-size: 15px !important;
  font-family: Helvetica Neue;
  font-weight: 400;
  color: #6c757d;
}
.quill .ql-container.ql-snow .ql-editor ol li {
  font-size: 15px !important;
  font-family: Helvetica Neue !important;
  font-weight: 700 !important;
  color: #6c757d !important;
}

.quill .ql-container.ql-snow .ql-editor ol li strong {
  font-size: 15px !important;
  font-family: Helvetica Neue !important;
  font-weight: 700 !important;
  color: #6c757d !important;
}

.ql-container.ql-snow {
  border: 1px solid #c1c1c1 !important;
}
.quill .ql-container.ql-snow .ql-editor.ql-blank::before {
  font-size: 15px !important;
  /* font-family: Helvetica Neue !important; */
  font-family: Manrope !important ;
  font-weight: 500 !important;
  color: #6c757d !important;
  font-style: normal !important;
  line-height: 24px;
}
.quill .ql-container.ql-snow .ql-editor {
  height: 219px !important;
  border-bottom-left-radius: 4px !important;
  border-bottom-right-radius: 4px !important;
  background-color: #ffffff;
  border-bottom: 1px solid #c1c1c1;
}

/*----------- Datepicker calender css----------- */
/* CALENDER  */
.dateModal {
  width: 100%;
  height: 100%;
}

.dateModal .modal-dialog .modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 395px;
  color: var(--bs-modal-color);
  pointer-events: auto;
  background-color: var(--bs-modal-bg);
  background-clip: padding-box;
  border: var(--bs-modal-border-width) solid var(--bs-modal-border-color);
  border-radius: var(--bs-modal-border-radius);
  outline: 0;
  padding: 18px;
  border: 1px solid #e0e0e0;
}

.react-calendar {
  width: 358px !important;
  max-width: 100% !important;
  background: white !important;
  border: 0px solid #a0a096 !important;
  font-family: Arial, Helvetica, sans-serif !important;
  line-height: 1.125em !important;
}

/* .react-calendar__navigation button {
  min-width: 34px;
  background: none;
} */

.react-calendar__navigation__arrow.react-calendar__navigation__prev2-button {
  display: none;
}

.react-calendar__navigation__arrow.react-calendar__navigation__next2-button {
  display: none;
}

.react-calendar__navigation__arrow.react-calendar__navigation__prev-button {
  height: 38px;
  width: 38px;
  background-color: #ff4300;
  border-radius: 50%;
  border: 1px solid #ff4300;
  /* margin-top: 5px; */
  color: #ff4300;
  font-size: 0px !important;
  background-image: url(../../Assests/Images/lesssthanActive.svg);
  background-repeat: no-repeat;
  background-position: center;
}

.react-calendar__navigation__arrow.react-calendar__navigation__next-button {
  height: 38px;
  width: 38px;
  background-color: #ff4300;
  border-radius: 50%;
  border: 1px solid #ff4300;
  /* margin-top: 5px; */
  color: #ff4300;
  font-size: 0px !important;
  background-image: url(../../Assests/Images/greaterthanActive.png);
  background-repeat: no-repeat;
  background-position: center;
}

.react-calendar__month-view__weekdays__weekday {
  text-decoration-line: none !important;
  padding: 0.5em;
  font-family: Manrope;
  font-size: 12px;
  font-weight: 500;
  color: #ff4300;
}

.react-calendar__month-view__weekdays__weekday abbr {
  text-decoration: none !important;
}

/* .react-calendar__tile:disabled {
  background-color: #ffffff !important;
  color: #2c3135 !important;
} */

.react-calendar__tile.react-calendar__tile--now.react-calendar__tile--active.react-calendar__tile--range.react-calendar__tile--rangeStart.react-calendar__tile--rangeEnd.react-calendar__tile--rangeBothEnds.react-calendar__month-view__days__day {
  background: #ff4300;
  font-family: "Poppins";
  font-weight: 700;
  font-size: 12px;
  color: #ffffff;
}

.react-calendar__tile.react-calendar__month-view__days__day.react-calendar__month-view__days__day--neighboringMonth {
  width: 50px !important;
  height: 42px !important;
  background: #eeeeee;
  font-family: "Poppins";
  font-size: 12px;
  font-weight: 500;
  color: #6f7377;
  flex: none !important;
}

.react-calendar__navigation__arrow.react-calendar__navigation__prev-button:enabled:hover {
  background-color: #ff4300;
  border: 1px solid #ff4300;
}

.react-calendar__navigation__arrow.react-calendar__navigation__next-button:enabled:hover {
  background-color: #ff4300;
  border: 1px solid #ff4300;
}

.react-calendar__navigation__arrow.react-calendar__navigation__prev-button:enabled:focus {
  background-color: #ff4300;
  border: 1px solid #ff4300;
}

.react-calendar__navigation__arrow.react-calendar__navigation__next-button:enabled:focus {
  background-color: #ff4300;
  border: 1px solid #ff4300;
}

button.react-calendar__tile.react-calendar__tile--now.react-calendar__month-view__days__day {
  background: #ff4300 !important;
  color: #ffffff !important;
}

button.react-calendar__tile.react-calendar__tile--active.react-calendar__tile--range.react-calendar__tile--rangeStart.react-calendar__tile--rangeEnd.react-calendar__tile--rangeBothEnds.react-calendar__month-view__days__day {
  background-color: #57575b !important;
  color: #fff !important;
}

.react-calendar__tile.react-calendar__month-view__days__day {
  background-color: #eee !important;
  color: #2c3135 !important;
  flex: none !important;
  font-family: "Poppins" !important;
  font-size: 12px !important;
  font-weight: 500 !important;
  height: 42px !important;
  margin: 1px;
  width: 50px !important;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
/*----------- Datepicker calender css end----------- */

/*-------------- select checkbox css---------- */

.selectCheckBox {
  margin-right: 40px;
}
.selectCheckBox .form-check-input:checked {
  background-color: #ff4300;
  border-color: #ff4300;
}

.selectCheckBox .form-check-input:focus {
  outline: none;
  border: none;
  box-shadow: none;
}

.selectCheckBox .form-check-label {
  color: #394858;
  font-size: 15px;
  font-weight: 500;
}


.selectCheckBoxClubName {
  margin-right: 40px;
}
.selectCheckBoxClubName .form-check-input:checked {
  background-color: #ff4300 !important;
  border-color: #ff4300 !important;
}

.selectCheckBoxClubName .form-check-input:focus {
  outline: none;
  border: none;
  box-shadow: none;
}

.selectCheckBoxClubName .form-check-label {
  color: #000000;
  font-size: 16px;
  font-weight: 500;
}

.react-datepicker__input-container .react-datepicker__calendar-icon {
  position: absolute;
  padding: 13px 0px 0px 10px !important;
  box-sizing: content-box;
}

.memberInfoCheck .form-check label {
  font-size: 16px;
  font-weight: 500;
  color: #000000;
}

.memberInfoCheck .form-check-input {
  background-color: #f5f4f4;
  border-color: #c1c1c1;
}

.memberInfoCheck .form-check-input:checked {
  background-color: #ff4300;
  border-color: #fff;
}

.memberInfoCheck .form-check-input:focus {
  border-color: #fff;
  outline: none;
  box-shadow: none;
}

/*------------ Add media query for manage responsive----------- */

@media only screen and (max-width: 400px) {
  .tabSection.nav-tabs .nav-link.active {
    font-size: 14px !important;
  }

  .tabSection.nav-tabs .nav-link {
    font-size: 14px !important;
  }

  .tabSection li.nav-item {
    margin-right: 10px;
    margin-bottom: 7px;
  }
}

/* @media screen and (min-width: 1299px) {
  .screenOverride .container {
    max-width: 1280px;
  }
} */

/*============ checkb0ox color css =============*/
.checkboxSelect {
  display: flex;
}
.checkboxSelect label {
  display: block;
  color: #394858;
  font-weight: 400;
  font-size: 15px;
  padding: 5px;
  margin-left: -12px;
}

.checkboxSelect input[type="radio"] {
  appearance: none;
  background-color: #fff;
  width: 20px;
  height: 20px;
  border: 2px solid #ccc;
  border-radius: 50px;
  display: inline-grid;
  place-content: center;
  margin-top: 6px;
  margin-right: 15px;
}

.checkboxSelect input[type="radio"]::before {
  content: "";
  width: 12px;
  height: 10px;
  transform: scale(0);
  transform-origin: bottom left;
  background-color: #fff;
  clip-path: polygon(13% 50%, 34% 66%, 81% 2%, 100% 18%, 39% 100%, 0 71%);
}

.checkboxSelect input[type="radio"]:checked::before {
  transform: scale(1);
}

.checkboxSelect input[type="radio"]:checked {
  background-color: #ff4300;
  border: 2px solid #ff4300;
}

/* ==============member phone input css============= */


.memberPhoneInputSet {
  position: relative;
}

.memberPhoneInputSet svg {
  position: absolute;
  bottom: 10px;
  left: 4px;
  font-size: 20px;
  z-index: 99;
  color: #ffffff;
}

.phoneCountry {
  position: relative;
  cursor: pointer;
}

.memberPhoneInputSet:before {
  content: "";
  border-top: 6px solid;
  border-right: 6px solid transparent;
  border-bottom: 0;
  border-left: 6px solid transparent;
  position: absolute;
  left: 93px;
  top: 21px;
  z-index: 999;
  color: #000 !important;
}

.customPhoneDropDown {
  position: absolute;
  top: -3px;
  left: 3px;
  width: 111px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.memberPhoneInputSet .form-control {
  position: relative;
  letter-spacing: 0.01rem;
  margin-top: 0 !important;
  margin-bottom: 0 !important;
  padding-left: 48px;
  margin-left: 0;
  background: #ffffff;
  /* border: none !important; */
  border-radius: 4px;
  line-height: 25px;
  height: 38px !important;
  width: 100% !important;
  max-width: 350px;
  outline: none;
  padding-left: 120px !important;
  font-weight: 500;
  font-size: 16.94px !important;
  border: 1px solid #c1c1c1;
}

.memberPhoneInputSet .form-control:focus {
  outline: none;
  box-shadow: none;
  border: 1px solid #c1c1c1;
}

.memberPhoneInputSet .react-tel-input .flag-dropdown {
  border: none !important;
  background: #000000 !important;
  width: 110px !important;
  border-top-left-radius: 7.26px !important;
  border-bottom-left-radius: 7.26px !important;
}

.memberPhoneInputSet .react-tel-input .selected-flag .flag {
  display: block !important;
  /* opacity: 0; */
  z-index: 0 !important;
  position: absolute !important;
  top: 15px !important;
  margin-top: 0px !important;
}

.react-tel-input .selected-flag .arrow {
  position: relative;
  top: 50%;
  margin-top: -2px;
  left: 20px;
  width: 0;
  height: 0;
  border-left: 3px solid transparent;
  border-right: 3px solid transparent;
  border-top: 4px solid #555;
  opacity: 0;
}

.memberPhoneInputSet .react-tel-input .selected-flag {
  outline: none;
  position: relative;
  width: 115px !important;
  height: 100%;
  padding: 0 0 0 8px;
  background: #e9e9e9 !important;
}

.memberPhoneInputSet .react-tel-input .selected-flag {
  border-top-left-radius: 4px !important;
  border-bottom-left-radius: 4px !important;
  border: 1px solid #c1c1c1;
}

.memberPhoneInputSet:before {
  content: "";
  border-top: 6px solid;
  border-right: 6px solid transparent;
  border-bottom: 0;
  border-left: 6px solid transparent;
  position: absolute;
  left: 93px;
  top: 18px;
  z-index: 1;
  color: #000 !important;
  display: none;
}

.react-tel-input .selected-flag .arrow {
  position: relative;
  top: 50%;
  margin-top: -2px;
  left: 20px;
  width: 0;
  height: 0;
  border-left: 3px solid transparent;
  border-right: 3px solid transparent;
  border-top: 4px solid #555;
  opacity: 0;
}

/* .memberPhoneInputSet p {
  font-size: 15px;
  z-index: 999;
  color: #000;
} */

.css-1dimb5e-singleValue {
  font-size: 15px;
  color: #6c757d !important;
  font-weight: 500;
}
.css-1xz3in2-control:hover {
  border-color: #c1c1c1 !important;
}

.css-mohuvp-dummyInput-DummyInput:focus {
  outline: none !important;
  box-shadow: none !important;
  border: none !important;
}

svg.react-datepicker__calendar-icon.undefined.false {
  display: none;
}

.react-datepicker__input-container .react-datepicker__calendar-icon {
  display: none;
}

.react-datepicker__view-calendar-icon input {
  padding: 0px 0px 0px 35px !important;
}
.react-datepicker__view-calendar-icon input {
  padding: 6px 10px 5px 25px;
  border-radius: 4px !important;
}

.react-datepicker__input-container .react-datepicker__calendar-icon {
  position: absolute;
  padding: 15px 0px 0px 10px !important;
  box-sizing: content-box;
}
.react-datepicker__triangle {
  position: absolute;
  pointer-events: none;
  left: 69px;
  transform: translateY(-1px);
  left: 66px;
  top: 244px;
}

.react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle {
  fill: #f0f0f0;
  color: #f0f0f0;
  left: 62px !important;
  top: -15px;
}

.react-datepicker-popper[data-placement^="top"] .react-datepicker__triangle {
  fill: #f0f0f0;
  color: #f0f0f0;
  left: 62px !important;
  top: 100% !important;
}

/* =========edit member detail css======== */
.datePicker .react-datepicker__view-calendar-icon input {
  background-color: #efefef !important;
  border: 1px solid #c4c4c4 !important;
  padding: 10px 0px 10px 30px !important;
  border-radius: 4px;
  width: 220px;
  margin-bottom: 12px;
}

.datePicker .react-datepicker__view-calendar-icon input:focus {
  border: none;
  box-shadow: none;
  outline: none;
}

.dobRequired .react-datepicker__view-calendar-icon input {
  background-color: #fff !important;
  border: 1px solid #c4c4c4 !important;
  padding: 8px 0px 8px 36px !important;
  border-radius: 4px;
  width: 350px;
}
.dobRequired .react-datepicker__view-calendar-icon input:focus {
  border: none;
  box-shadow: none;
  outline: none;
}

/*============= member delete modal =========*/
.confirmDeleteCheck .form-check label {
  font-size: 14px;
  font-weight: 400;
  color: #000000;
}

.confirmDeleteCheck .form-check-input {
  background-color: #f5f4f4;
  border-color: #c1c1c1;
}

.confirmDeleteCheck .form-check-input:checked {
  background-color: #ff4300;
  border-color: #fff;
}

.confirmDeleteCheck .form-check-input:focus {
  border-color: #fff;
  outline: none;
  box-shadow: none;
}

/*============= add logbook edit member =========*/
.timeCheck {
  display: flex;
  justify-content: center;
}
.timeCheck .form-check label {
  font-size: 18px;
  font-weight: 600;
  color: #000000;
}

.timeCheck .form-check-input {
  background-color: #f5f4f4;
  border-color: #c1c1c1;
}

.timeCheck .form-check-input:checked {
  background-color: #ff4300;
  border-color: #fff;
}

.timeCheck .form-check-input:focus {
  border-color: #fff;
  outline: none;
  box-shadow: none;
}

/*------------- Distance module css------------- */
.checkboxSelectKm {
  display: flex;
}
.checkboxSelectKm label {
  display: block;
  color: #394858;
  font-weight: 500;
  font-size: 15px;
  padding: 5px;
  margin-left: -12px;
}

.checkboxSelectKm input[type="radio"] {
  appearance: none;
  background-color: #fff;
  width: 20px;
  height: 20px;
  border: 2px solid #ccc;
  border-radius: 50px;
  display: inline-grid;
  place-content: center;
  margin-top: 6px;
  margin-right: 15px;
}

.checkboxSelectKm input[type="radio"]::before {
  content: "";
  width: 12px;
  height: 10px;
  transform: scale(0);
  transform-origin: bottom left;
  background-color: #fff;
  clip-path: polygon(13% 50%, 34% 66%, 81% 2%, 100% 18%, 39% 100%, 0 71%);
}

/* .checkboxSelectKm input[type="radio"]:checked::before {
  transform: scale(1);
} */

.checkboxSelectKm input[type="radio"]:checked {
  background-color: #ff4300;
  border: 2px solid #ff4300;
}

/* .selectDistanceCheckbox {
  margin-right: 60px;
} */
.selectDistanceCheckbox .form-check-input:checked {
  background-color: #ff4300;
  border-color: #ff4300;
}

.selectDistanceCheckbox .form-check-input:focus {
  outline: none;
  border: none;
  box-shadow: none;
}

.selectDistanceCheckbox .form-check-label {
  color: #394858;
  font-size: 15px;
  font-weight: 500;
  margin-bottom: 10px;
}

/*======== image cropper css============*/
.reactEasyCrop_Container {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: hidden;
  user-select: none;
  touch-action: none;
  cursor: move;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 268px;
  width: 498px;
}

@media screen and (min-width: 320px) and (max-width: 575px) {
  .reactEasyCrop_Container {
    width: 302px;
    height: 268px;
  }
}

/* remove warning error in email input */
.form-control.is-invalid,
.was-validated .form-control:invalid {
  border-color: var(--bs-form-invalid-border-color);
  padding-right: calc(1.5em + 0.75rem);
  background-image: none !important;
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}

.validateError {
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: red;
}

.savemodalHead {
  background-color: rgb(245, 244, 244);
  height: 67px;
  border-bottom: 1px solid #c1c1c1;
}

.saveModalFooter {
  background-color: rgb(245, 244, 244);
  height: 67px;
  border-top: 1px solid #c1c1c1;
}
.saveModalBody {
  height: 70px;
}

/* .modal-content {
  width: 100% !important;
  max-width: 400px !important;
  margin: 0px auto !important;
} */

.modalCustom .modal-content {
  width: 100% !important;
  max-width: 380px !important;
  margin: 0px auto !important;
}

.modalCloseBtn .btn-close:focus {
  outline: none !important;
  box-shadow: none !important;
}

.form-check-input:checked {
  background-color: #ff4300 !important;
  border-color: #828282 !important;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.custom-datepicker {
  font-size: 14px;
  width: 242px;
}

.dobGroup {
  position: relative;
}

.dobGroup svg {
  position: absolute;
  left: 8px;
  z-index: 1;
  font-size: 25px;
  margin-top: 7px;
}

.memberCheck .form-check label {
  font-size: 13px;
  font-weight: 500;
  color: #394858;
}

.memberCheck .form-check-input {
  background-color: #f5f4f4;
  border-color: #c1c1c1;
  width: 18px;
  height: 18px;
}

.memberCheck .form-check-input:checked {
  background-color: #ff4300;
  border-color: #fff;
  border: 1px solid #ff4300;
}

.memberCheck .form-check-input:focus {
  border-color: #fff;
  outline: none;
  box-shadow: none;
}

.dobGroup .form-control:disabled {
  background-color: #fff !important;
  background-color: #fff !important;
  opacity: 1;
}

.ValidGroup {
  position: relative;
}

.ValidGroup svg {
  position: absolute;
  left: 8px;
  z-index: 1;
  font-size: 25px;
  margin-top: 7px;
}

.ValidGroup .form-control:disabled {
  background-color: #efefef !important;
  background-color: #efefef !important;
  opacity: 1;
}

.react-calendar {
  width: 358px !important;
  max-width: 100% !important;
  background: white !important;
  border: 0px solid #a0a096 !important;
  font-family: Arial, Helvetica, sans-serif !important;
  line-height: 1.125em !important;
}

.react-calendar__navigation__arrow.react-calendar__navigation__prev2-button {
  display: none;
}

.react-calendar__navigation__arrow.react-calendar__navigation__next2-button {
  display: none;
}

.lg-react-element.custom-wrapper-class {
  width: 510px;
  display: flex;
  flex-wrap: wrap;
}

.lg-react-element.custom-wrapper-class > * {
  flex: 1 1 23%;
  margin: 5px;
  max-width: calc(25% - 10px);
}

@media screen and (min-width: 320px) and (max-width: 991px) {
  .lg-react-element.custom-wrapper-class {
    width: 100%;
  }
}

/* new css react select */

/* .css-qbdosj-Input {
  background: linear-gradient(180deg, #fff, #ececec 99.41%);
  border: 1px solid #c4c4c4;
  border-radius: 5px;
  height: 38px;
  min-width: 350px;
}

.css-1fdsijx-ValueContainer {
padding: 0px !important;
}

.css-1tkm9rf-control {
  border: none !important;
} */

/* .reactSelect{
  color: #6c757d;
  font-family: Manrope;
  font-size: 15px;
  font-weight: 500;
  line-height: 24px;
}

.css-1tkm9rf-control {
  background: linear-gradient(180deg, #fff, #ececec 99.41%);
  border: 1px solid #c4c4c4;
  border-radius: 5px;
  height: 38px;
  min-width: 350px;
}

.css-1fdsijx-ValueContainer {
  background: linear-gradient(180deg, #fff, #ececec 99.41%);
  border: 1px solid #c4c4c4;
  border-radius: 5px;
  height: 38px;
  min-width: 350px;
} */

.css-1tkm9rf-control {
  background: linear-gradient(180deg, #fff, #ececec 99.41%);
  border: 1px solid #c4c4c4;
  border-radius: 5px;
  height: 42px;
  min-width: 350px;
  position: relative !important;
}

.css-qbdosj-Input {
  margin: 0px !important;
  padding: 0px !important;
}

.css-19bb58m {
  margin: -2px !important;
}

.css-1hm4x9g-control {
  background: linear-gradient(180deg, #fff, #ececec 99.41%);
  border: 1px solid #c4c4c4;
  border-radius: 5px;
  height: 42px;
  min-width: 350px;
  position: relative !important;
}

.css-1hm4x9g-control:hover {
  border: 1px solid #c4c4c4 !important;
}

.css-1hm4x9g-control:focus {
  border: 1px solid #c4c4c4 !important;
}

.css-tj5bde-Svg {
  color: #3f3f3f !important;
  font-size: 15px;
  font-weight: 600;
}

.css-1u9des2-indicatorSeparator {
  width: 0px !important;
}

.ss-1jqq78o-placeholder {
  margin-top: -3px;
}

.btn:disabled {
  border: 1px solid #ff4300;
}

.form-select.is-invalid:not([multiple]):not {
  background-size: 0px !important;
}

.react-calendar__navigation button {
  color: #2c3135;
  /* font-family: Poppins; */
  font-size: 20px !important;
  font-weight: 700 !important;
  min-width: 34px;
}

.react-calendar__tile:disabled {
  background-color: #fff !important;
  color: #2c3135 !important;
}

@media screen and (min-width: 320px) and (max-width: 576px) {
  .css-1tkm9rf-control {
    background: linear-gradient(180deg, #fff, #ececec 99.41%);
    border: 1px solid #c4c4c4;
    border-radius: 5px;
    height: 42px;
    min-width: 300px;
    position: relative !important;
  }

  .css-tj5bde-Svg {
    color: #3f3f3f !important;
    font-size: 15px;
    font-weight: 600;
  }
}

@media screen and (min-width: 577) and (max-width: 767) {
  .css-1tkm9rf-control {
    background: linear-gradient(180deg, #fff, #ececec 99.41%);
    border: 1px solid #c4c4c4;
    border-radius: 5px;
    height: 42px;
    min-width: 400px;
    position: relative !important;
  }

  .css-tj5bde-Svg {
    color: #3f3f3f !important;
    font-size: 15px;
    font-weight: 600;
  }
}

.react-calendar__tile.react-calendar__month-view__days__day.react-calendar__month-view__days__day--neighboringMonth {
  background: #eee;
  color: #6f7377 !important;
  flex: none !important;
  font-family: "Poppins";
  font-size: 12px;
  font-weight: 500;
  height: 42px !important;
  width: 50px !important;
}

/* UPDATED CROPPER */
.croperModal .modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 90vh !important;
  height: 70vh !important;
  color: var(--bs-modal-color);
  pointer-events: auto;
  background-color: var(--bs-modal-bg);
  background-clip: padding-box;
  border: var(--bs-modal-border-width) solid var(--bs-modal-border-color);
  border-radius: var(--bs-modal-border-radius);
  outline: 0;
}
.croperModal .modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: 0;
}

@media screen and (min-width: 320px) and (max-width: 991px) {
  .croperModal .modal-content {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100% !important;
    color: var(--bs-modal-color);
    pointer-events: auto;
    background-color: var(--bs-modal-bg);
    background-clip: padding-box;
    border: var(--bs-modal-border-width) solid var(--bs-modal-border-color);
    border-radius: var(--bs-modal-border-radius);
    outline: 0;
  }
}

.form-select.is-invalid:not([multiple]):not([size]),
.form-select.is-invalid:not([multiple])[size="1"],
.was-validated .form-select:invalid:not([multiple]):not([size]),
.was-validated .form-select:invalid:not([multiple])[size="1"] {
  --bs-form-select-bg-icon: url(data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 12 12%27 width=%2712%27 height=%2712%27 fill=%27none%27 stroke=%27%23dc3545%27%3e%3ccircle cx=%276%27 cy=%276%27 r=%274.5%27/%3e%3cpath stroke-linejoin=%27round%27 d=%27M5.8 3.6h.4L6 6.5z%27/%3e%3ccircle cx=%276%27 cy=%278.2%27 r=%27.6%27 fill=%27%23dc3545%27 stroke=%27none%27/%3e%3c/svg%3e);
  padding-right: 4.125rem;
  background-position: right 0.75rem center, center right 2.25rem;
  background-size: 350px;
}

.custom-select .css-1n7v3ny-option {
  white-space: normal; /* Allow line breaks */
  overflow-wrap: break-word !important; /* Break long words */
}


.form-control:focus {
  border: 1px solid #c1c1c1;
  box-shadow: none;
  outline: none;

}


/* distance radio css */

.switch-field {
  display: flex;
  align-items: center;
  /* margin-left: 15px; */
  margin-left: -45px;
}

.switch-field input {
  /* position: absolute !important; */
  /* clip: rect(0, 0, 0, 0);
  height: 1px;
  width: 1px;
  border: 0;
  overflow: hidden; */
  display: none;
}

.switch-field .form-check input[type="radio"] {
  display: none;
}

.switch-field label {
  background-color: #ffffff !important;
  color: rgba(0, 0, 0, 0.6);
  font-size: 14px;
  line-height: 1;
  text-align: center;
  padding: 10px;
  border: 1px solid #ff4300 !important;
  transition: all 0.1s ease-in-out;
  font-size: 14px;
  font-weight: 700;
}

.switch-field label:hover {
  cursor: pointer;
}

.switch-field input:checked + label {
  background-color: #ff4300 !important;
  box-shadow: none;
  border: 1px solid #ff4300 !important;
  color: #ffffff;
  font-size: 14px;
  font-weight: 700;
}

.switch-field .form-check {
  padding: 0px !important;
}

.switch-field .form-check:first-child label {
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}

.switch-field .form-check:last-child label {
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}
