.topHead h2 {
  color: #000000;
  font-size: 21px;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 0.48px;
}

.dashBox {
  border: 1px solid #c4c4c4;
  margin-top: 10px;
  text-align: center;
  width: 100%;
  height: 70px;
  border-radius: 4px;
  background-color: #fff;
  cursor: pointer;
}
.devider {
  width: 100%;
  height: 1px;
  background: #c1c1c1;
  margin: 15px 0px;
}

.dashBox p {
  color: #6c757d;
  font-size: 12px;
  font-weight: 500;
  margin-bottom: 3px;
  text-transform: uppercase;
  line-height: 24px;
  margin-top: 5px;
}

.dashBox h1 {
  color: #6c757d;
  font-size: 30px;
  font-weight: 600;
  line-height: 24px;
}

.eventBtn {
  display: flex;
  justify-content: space-between;
  margin-top: 15px;
}

.dashBtn {
  text-align: center;
}

.dashBtn button {
  height: 40px;
  width: 100%;
  margin-top: 20px;
  margin-right: 10px;
}

.dashBtn button p {
  text-transform: uppercase;
}
