.devider {
  width: 100%;
  height: 1px;
  background: #c1c1c1;
  margin: 20px 0px;
}

.eventListBox {
  height: 120px;
  width: 100%;
  border-radius: 4px;
  background-color: #ffffff;
  border: 1px solid #cbcbcb;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0px 15px;
  margin-bottom: 10px;
}
.imageSection {
  width: 100px !important;
  height: 100px !important;
  border-radius: 4px !important;
  /* object-fit: contain; */
  margin-right: 15px;
  background-color: #404040;
}
.detail {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.detail p {
  opacity: 1;
  color: rgba(0, 0, 0, 1);
  font-family: Manrope;
  font-size: 15px;
  font-weight: 500;
  letter-spacing: 0.3px;
  margin: 0;
}
.detail h1 {
  opacity: 1;
  color: rgba(0, 0, 0, 1);
  font-family: Manrope;
  font-size: 15px;
  font-weight: 700;
  letter-spacing: 0.3px;
}
.detail span {
  opacity: 1;
  color: rgba(0, 0, 0, 1);
  font-family: Manrope;
  font-size: 15px;
  font-weight: 400;
  letter-spacing: 0.3px;
  margin: 0;
}
.detail h2 {
  opacity: 1;
  color: rgba(255, 67, 0, 1);
  font-family: Manrope;
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 0.24px;
  margin: 0;
}

.editCross {
  height: 58px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.editCross img {
  width: 24px;
  height: 24px;
}

.inviteormore {
  width: 100%;
  height: 60px;
  background: #ffffff;
  margin-top: 25px;
  padding: 10px;
  display: flex;
  justify-content: space-between;
  border: 1px solid #b5b5b5;
  border-radius: 5px;
  align-items: center;
}
.membersCount {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.membersCount p {
  font-family: Manrope;
  font-size: 14px;
  font-weight: 700;
  color: #ff4300;
  margin: 0;
}
.membersCount span {
  font-family: Manrope;
  font-size: 14px;
  font-weight: 700;
  color: #000000;
  margin: 0;
}

.scheduleHeader {
  height: 58px;
  width: 100%;
  padding: 0px 10px;
  border: 1px solid #404040;
  border-radius: 5px;
  background-color: #404040;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
  margin-bottom: 20px;
}
.scheduleBoxOne {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 90%;
}
.iconHolder {
  width: 28px;
  height: 28px;
  border-radius: 50%;
  background-color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
}
.scheduleDate p {
  font-family: Manrope;
  font-weight: 300;
  font-size: 16px;
  line-height: 19px;
  color: #ffffff;
  margin: 0;
}
.scheduleDate span {
  font-family: Manrope;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  color: #ffffff;
  margin: 0;
}

.adviceBox {
  width: 100%;
  height: 114px;
  border-radius: 4px;
  background-color: #ff4300;
  border: 1px solid #ff4300;
  margin-top: 10px;
  margin-bottom: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0px 34px;
  text-align: center;
}
.adviceBox p {
  opacity: 1;
  color: rgba(255, 255, 255, 1);
  font-family: Manrope;
  font-size: 18px;
  font-weight: 900;
  margin: 0;
}
.adviceBox img {
  height: 34px;
  width: 147px;
}
.adviceBox h2 {
  opacity: 1;
  color: rgba(255, 255, 255, 1);
  font-family: Manrope;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
}
.eventListBoxCopy {
  height: 120px;
  width: 100%;
  border-radius: 4px 4px 0px 0px;
  background-color: #ffffff;
  border: 1px solid #cbcbcb;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0px 15px;
}
.copySection {
  width: 100%;
  height: 52px;
  border-radius: 0px 0px 4px 4px;
  background-color: #eeeeee;
  border-left: 1px solid #cbcbcb;
  border-right: 1px solid #cbcbcb;
  border-bottom: 1px solid #cbcbcb;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px 15px;
}
.copySection p {
  opacity: 1;
  color: rgba(0, 0, 0, 1);
  font-family: Manrope;
  font-size: 14px;
  font-weight: 600;
  margin: 0;
}
.activeIcon {
  width: 24px;
  height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgb(7, 124, 7);
  border-radius: 4px;
  cursor: pointer;
}

.noDataFound p {
  font-family: Manrope;
  font-weight: 700;
  font-size: 15px;
  line-height: 23px;
  color: #212529;
  margin: 0;
}
.noDataFound span {
  font-family: Manrope;
  font-weight: 500;
  font-size: 15px;
  line-height: 23px;
  color: #212529;
  margin: 0;
}
