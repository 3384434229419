.modalHead p {
  font-size: 20px;
  font-weight: 400;
  font-family: Manrope;
  color: #ff4300;
  margin: 0;
}

.modalHead span {
  font-size: 20px;
  font-weight: 700;
  font-family: Manrope;
  color: #ff4300;
  margin: 0;
}
.modalBody p {
  font-size: 16px;
  font-weight: 400;
  font-family: Manrope;
  color: #000000;
  margin: 0;
  margin-top: 4px;
}

.modalBody span {
  font-size: 16px;
  font-weight: 600;
  font-family: Manrope;
  color: #000000;
  margin: 0;
}

.btnSection {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
}

.rightBtn {
  display: flex;
}

.close {
  width: 90px;
  height: 34px;
  border: 1px solid #000000;
  border-radius: 5px;
  background-color: #ffffff;
  font-size: 14px;
  font-weight: 500;
  color: #000000;
}
.delete {
  width: 131px;
  height: 34px;
  border: 1px solid #ff4300;
  border-radius: 5px;
  background-color: #ff4300;
  font-size: 14px;
  font-weight: 500;
  color: #ffffff;
}

/*-------- Add media query for manage responsive --------*/
@media screen and (max-width: 575px) {
  .close {
    width: 115px;
    height: 40px;
    font-size: 12px;
    margin-right: 5px;
  }
  .delete {
    width: 115px;
    height: 40px;
    font-size: 12px;
    margin-right: 5px;
  }
}
