.AppRoot {
  display: flex;
  height: 100vh;
  overflow-y: auto;
  overflow-x: hidden;
  background-color: #f5f4f4;
  width: 100%;
}

.MainPage {
  flex-grow: 0;
  /* width: 100%; */
  overflow-x: hidden;
  overflow-y: auto;
  padding: 46px 0px 25px 39px;
  width: 80%;
}

.mainPageChild {
  width: 500px;
}

.MainPage::-webkit-scrollbar {
  width: 0px;
}

.MainPage::-webkit-scrollbar-track {
  background: none;
}

.MainPage::-webkit-scrollbar-thumb {
  background: none;
}

@media only screen and (max-width: 991px) {
  .mainPageChild {
    width: 100%;
  }
  .AppRoot {
    display: block;
    height: 100vh;
    overflow-y: auto;
    overflow-x: hidden;
  }
  .MainPage {
    flex-grow: 0;
    overflow: auto;
    min-height: 100%;
    width: 100%;
    max-width: none;
    padding: 5px 5px 5px 5px;
  }
}

@media only screen and (max-width: 767px) {
  .AppRoot {
    display: grid;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1200px) {
  .MainPage {
    width: 60%;
  }
}
