.brandSelect {
  min-height: 75px;
  margin-top: 5px;
  position: relative;
  width: 278px;
}
.brandSelect label p {
  opacity: 1;
  color: #394858;
  font-family: Manrope;
  font-size: 14.5px;
  font-weight: 500;
  margin: 0;
}
.brandSelect select {
  width: 278px;
  height: 38px;
  border-radius: 4px;
  border: 1px solid #c4c4c4;
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 1) 0%,
    rgba(236, 236, 236, 1) 100%
  );
  opacity: 1;
  color: rgba(108, 117, 125, 1);
  font-family: Manrope;
  font-size: 15px;
  font-weight: 500;
}

.brandSelect svg {
  position: absolute;
  top: 35px;
  right: 15px;
  font-size: 30px;
}

.brandSelect input {
  height: 38px;
  width: 278px;
  border-radius: 4px;
  background: #ffffff;
  border: 1px solid #c4c4c4;
  opacity: 1;
  color: rgba(108, 117, 125, 1);
  font-family: Manrope;
  font-size: 15px;
  font-weight: 500;
  margin: 0;
}
.brandSelect input::placeholder {
  opacity: 1;
  color: rgba(108, 117, 125, 1);
  font-family: Manrope;
  font-size: 15px;
  font-weight: 500;
  margin: 0;
}
.brandSelect input:focus {
  box-shadow: none;
  outline: none;
  border: 1px solid #c4c4c4;
  color: rgba(108, 117, 125, 1);
  font-family: Manrope;
  font-size: 15px;
  font-weight: 500;
  margin: 0;
}

@media screen and (min-width: 320px) and (max-width: 575px) {
  .brandSelect {
    width: 100%;
  }
  .brandSelect select {
    width: 100%;
  }
  .brandSelect svg {
    position: absolute;
    top: 35px;
    right: 15px;
    font-size: 30px;
  }
  .brandSelect input {
    width: 100%;
  }
}


/* .event {
  margin: 0px 0px 15px 0px;
  position: relative;
}
.event label {
  font-family: Manrope;
  font-size: 14.5px;
  font-weight: 500;
  line-height: 19.81px;
  color: #000000;
}
.event input {
  width: 350px;
  max-width: 100%;
  height: 38px;
  border: 1px solid #c4c4c4;
  background: #ffffff;
  border-radius: 5px;
}
.event input::placeholder {
  font-family: Manrope;
  font-size: 15px;
  font-weight: 500;
  line-height: 24px;
  color: #6c757d;
}
.event select {
  font-family: Manrope;
  height: 38px;
  min-width: 310px;
  max-width: 350px;
  border: 1px solid #c4c4c4;
  border-radius: 5px;
  background: linear-gradient(180deg, #ffffff 0%, #ececec 99.41%);
  font-size: 15px;
  color: #6c757d;
  font-weight: 500;
  line-height: 24px;
}
.event p {
  position: absolute;
  font-family: Manrope;
  font-weight: 500;
  font-size: 15px;
  line-height: 24px;
  color: #000000;
  z-index: 1;
  margin: 0;
  top: 36px;
  left: 35px;
}
.event option {
  font-family: Manrope;
  font-size: 15px;
  font-weight: 500;
  color: #6c757d;
}

.event svg {
  position: absolute;
  top: 35px;
  right: 15px;
  font-size: 30px;
} */



.event {
  min-height: 75px;
  margin-top: 5px;
  position: relative;
  width:449px;
}
.event label p {
  opacity: 1;
  color: #394858;
  font-family: Manrope;
  font-size: 14.5px;
  font-weight: 500;
  margin: 0;
}
.event select {
  width: 449px;
  height: 38px;
  border-radius: 4px;
  border: 1px solid #c4c4c4;
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 1) 0%,
    rgba(236, 236, 236, 1) 100%
  );
  opacity: 1;
  color: rgba(108, 117, 125, 1);
  font-family: Manrope;
  font-size: 15px;
  font-weight: 500;
  position: relative;
}

.event svg {
  position: absolute;
  top: 10px;
  right:15px;
  font-size: 59px;
}

.event input {
  height: 38px;
  width: 449px;
  border-radius: 4px;
  background: #ffffff;
  border: 1px solid #c4c4c4;
  opacity: 1;
  color: rgba(108, 117, 125, 1);
  font-family: Manrope;
  font-size: 15px;
  font-weight: 500;
  margin: 0;
}
.event input::placeholder {
  opacity: 1;
  color: rgba(108, 117, 125, 1);
  font-family: Manrope;
  font-size: 15px;
  font-weight: 500;
  margin: 0;
}
.event input:focus {
  box-shadow: none;
  outline: none;
  border: 1px solid #c4c4c4;
  color: rgba(108, 117, 125, 1);
  font-family: Manrope;
  font-size: 15px;
  font-weight: 500;
  margin: 0;
}


.selectOptionWorkout {
  min-height: 75px;
  margin-top: 20px;
  position: relative;
  width: 278px;
}
.selectOptionWorkout label p {
  opacity: 1;
  color: #000;
  font-family: Manrope;
  font-size: 14.5px;
  font-weight: 400;
  margin: 0;
}

.selectOptionWorkout label span {
  opacity: 1;
  color: #000;
  font-family: Manrope;
  font-size: 14.5px;
  font-weight: 700;
  margin: 0;
}
.selectOptionWorkout select {
  width: 466px;
  height: 38px;
  border-radius: 4px;
  border: 1px solid #c4c4c4;
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 1) 0%,
    rgba(236, 236, 236, 1) 100%
  );
  opacity: 1;
  /* color: rgba(108, 117, 125, 1); */
  color: #000000;
  font-family: Manrope;
  font-size: 15px;
  font-weight: 500;
}

.selectOptionWorkout svg {
  position: absolute;
  top: 35px;
  right: -182px;
  font-size: 30px;
}


@media screen and (min-width: 320px) and (max-width: 575px) {
  .event {
    width: 100%;
  }
  .event select {
    width: 100%;
  }
  .event svg {
    position: absolute;
    top: 35px;
    right: 15px;
    font-size: 30px;
  }
  .event input {
    width: 100%;
  }
}



