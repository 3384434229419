.Sidebar {
  background: #000000;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 260px !important;
  box-shadow: 0px 0px 15px 0px #0000000d;
  height: 100vh;
  overflow-y: auto;
  overflow-x: hidden;
  border-radius: 0px;
}

.superAdminBg {
  background-color: #000000;
  position: fixed;
  display: flex;
  justify-content: center;
  width: 225px;
}
.SideBarHeader {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 23px;
  width: 190px;
  height: 46px;
  background-color: #ff4300;
  margin: 31px auto;
  margin-left: 34px;
  border-radius: 5px;
}
.Sidebar::-webkit-scrollbar {
  width: 2px;
}

.Sidebar::-webkit-scrollbar-track {
  background: #f1f1f1;
}

.Sidebar::-webkit-scrollbar-thumb {
  background: #888;
}

.NavItems {
  margin-top: 115px;
  background: none;
  display: flex;
  flex-direction: column !important;
  align-items: center;
  width: 260px !important;
  color: #000000;
}

.Navlink {
  text-decoration: none;
  font-size: 14px;
  line-height: 22px;
  font-weight: 500;
  display: flex;
  align-items: center;
  color: #ffffff;
  width: 190px;
  height: 46px;
  border: 1.2px solid #ffffff;
  border-radius: 5px;
  margin-bottom: 10px;
}

.Navlink svg {
  color: #fff;
  font-weight: 900;
}
.NavlinkButton {
  text-decoration: none;
  font-size: 14px;
  line-height: 22px;
  font-weight: 500;
  display: flex;
  align-items: center;
  color: #ffffff;
  width: 190px;
  height: 46px;
  border: 1.2px solid #ffffff;
  border-radius: 5px;
  margin-bottom: 10px;
  cursor: pointer;
}

.SideBarHeader h2 {
  font-size: 13px;
  font-weight: 700;
  margin-top: 10px;
  color: #fff;
}

@media screen and (max-width: 991px) {
  .TopNavLogo {
    display: none;
  }
}

@media only screen and (max-width: 991px) {
  .Sidebar {
    background: #000000;
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 50px;
    width: 50px !important;
    box-shadow: 0px 0px 15px 0px #0000000d;
    overflow-y: auto;
    overflow-x: hidden;
    border-radius: 4px;
  }
  .NavItems {
    margin-top: 115px;
    background: #000000;
    display: flex;
    flex-direction: column !important;
    align-items: center;
    width: 260px;
    color: #000000;
    height: auto;
    /* overflow: scroll; */
    scrollbar-width: none;
  }
  .SideBarHeader {
    position: fixed;
    display: flex;
    justify-content: center;
    margin-bottom: 23px;
    width: 193px;
    height: 50px;
    background-color: #ff4300;
    margin: 25px auto;
    margin-left: 34px;
  }

  .SideBarHeader h2 {
    font-size: 13px;
    font-weight: 700;
    margin-top: 20px;
    color: #fff;
  }
}
