.devider {
    width: 100%;
    height: 1px;
    background: #c1c1c1;
    margin: 20px 0px;
  }
  .userName {
    height: 58px;
    width: 100%;
    border-radius: 4px;
    background-color: #404040;
    display: flex;
    align-items: center;
    padding: 0px 10px;
  }
  .userName img {
    width: 46px;
    height: 46px;
    border-radius: 50%;
  }
  
  .userName p {
    font-family: Manrope;
    font-weight: 600;
    font-size: 15px;
    color: #ffffff;
    margin: 0px 0px 0px 10px;
  }
  .runDetailBox {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 65px;
    border-bottom: 0.5px solid #c1c1c1;
  }
  .marathonName {
    font-family: Manrope;
    font-weight: 700;
    font-size: 14px;
    line-height: 19px;
    color: #000000;
    margin: 0;
  }
  .marathonTiming p {
    font-family: Manrope;
    font-size: 14px;
    font-weight: 300;
    line-height: 19px;
    color: #000000;
    margin: 0;
  }
  .marathonTiming span {
    font-family: Manrope;
    font-size: 14px;
    font-weight: 700;
    line-height: 19px;
    color: #000000;
    margin: 0;
  }
  .runDetailBoxEdit {
    display: flex;
    width: 60px;
    justify-content: space-between;
  }
  .runDetailBoxEdit img {
    width: 24px;
    height: 24px;
  }
  .bottomSection {
    height: 60px;
    width: 100%;
    border-radius: 5px;
    border: 1px solid #b5b5b5;
    background-color: #ffffff;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0px 15px;
    margin-top: 15px;
  }
  .bottomSection button {
    height: 34px;
    width: 110px;
    border-radius: 4px;
    background-color: #ff4300;
    border: 1px solid #ff4300;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .bottomSection button p {
    font-family: Manrope;
    font-weight: 400;
    font-size: 14px;
    color: #ffffff;
    margin: 0;
  }
  .bottomSection button span {
    font-family: Manrope;
    font-weight: 700;
    font-size: 14px;
    color: #ffffff;
  }
  .result p {
    font-family: Manrope;
    font-weight: 600;
    font-size: 14px;
    color: #ff4300;
    margin: 0;
  }
  .result span {
    font-family: Manrope;
    font-weight: 600;
    font-size: 14px;
    color: #000000;
    margin: 0;
  }
  
  .scheduleHeader {
    height: 52px;
    width: 100%;
    padding: 0px 10px;
    border: 1px solid #ced4da;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    background-color: #ff4300;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 20px;
  }
  .scheduleBoxOne {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 90%;
  }
  .iconHolder {
    width: 28px;
    height: 28px;
    border-radius: 50%;
    background-color: #ffffff;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .scheduleDate p {
    font-family: Manrope;
    font-weight: 300;
    font-size: 18px;
    line-height: 19px;
    color: #ffffff;
    margin: 0;
  }
  .scheduleDate span {
    font-family: Manrope;
    font-weight: 600;
    font-size: 18px;
    line-height: 19px;
    color: #ffffff;
    margin: 0;
  }
  .fields {
    width: 100%;
    border: 1px solid #ced4da;
    padding: 10px 15px;
    background: #fff;
  }
  .inputArea {
    position: relative;
    margin-top: 15px;
  }
  .inputLabel p {
    font-family: Manrope;
    font-size: 16px;
    font-weight: 400;
    height: 19px;
    color: #394858;
    margin: 0;
  }
  .inputLabel span {
    font-family: Manrope;
    font-size: 16px;
    font-weight: 500;
    height: 19px;
    color: #394858;
    margin: 0;
  }
  .inputSelect {
    height: 38px;
    width: 100%;
    border: 1px solid #cdcdcd;
    border-radius: 4px;
    background: linear-gradient(180deg, #fff 0%, #ddd7d7 99.41%);
    font-family: Manrope;
    font-weight: 400;
    font-size: 15px;
    color: #000000;
  }
  .inputArea img {
    width: 15px;
    height: 9px;
    position: absolute;
    top: 40px;
    right: 20px;
  }
  .timeAndPace {
    display: flex;
    flex-direction: row;
    margin-top: 15px;
  }
  .timeHeading {
    font-family: Manrope;
    font-weight: 700;
    font-size: 16px;
    line-height: 22px;
    color: #394858;
    margin: 0px;
  }
  .pace {
    margin-left: 75px;
    margin-top: 0px;
  }
  .pace p {
    font-family: Manrope;
    font-weight: 700;
    font-size: 16px;
    color: #394858;
    margin: 0;
  }
  .pace input {
    margin-top: 3px;
    width: 120px;
    height: 38px;
    border-radius: 4px;
    background-color: #f1f1f1;
    border: 1px solid #f1f1f1;
    font-family: Manrope;
    font-weight: 600;
    font-size: 16px;
    line-height: 18px;
    color: #000000;
    text-align: center;
  }
  .pace input {
    box-shadow: none;
    outline: none;
    border: 1px solid #f1f1f1;
    min-width: 121px;
  }
  .inputBox {
    display: flex;
    position: relative;
  }
  .inputContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 5px;
  }
  .inputContainer input {
    width: 60px;
    height: 38px;
    border-radius: 4px;
    border: 1px solid #c4c4c4;
    background-color: #ffffff;
    text-align: center;
  
    opacity: 1;
    color: rgba(0, 0, 0, 1);
    font-family: Manrope;
    font-size: 15px;
    font-weight: 500;
  }
  .inputContainer input:focus {
    box-shadow: none;
    outline: none;
    border: 1px solid #c4c4c4;
  }
  .inputContainer p {
    font-family: Manrope;
    font-weight: 500;
    font-size: 11px;
    line-height: 24px;
    color: #6c757d;
  }
  .scheduleFooter {
    height: 58px;
    width: 100%;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    border: 1px solid #ced4da;
    background-color: #ececec;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0px 10px;
  }
  .cancelOrDelete {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 175px;
  }
  .cancelOrDelete button {
    width: 81px;
    height: 34px;
    border-radius: 4px;
    border: 1px solid #000000;
    background-color: #ffffff;
    font-family: Manrope;
    font-size: 14px;
    font-weight: 700;
    color: #000000;
  }
  .saveButton button {
    width: 81px;
    height: 34px;
    border-radius: 4px;
    border: 1px solid #ff4300;
    background-color: #ff4300;
    font-family: Manrope;
    font-size: 14px;
    font-weight: 700;
    color: #ffffff;
  }
  .saveButton button :hover {
    border: 1px solid #fa6833 !important;
    background-color: #fa6833 !important; /* Custom hover color */
  }
  .dateModal {
    width: 100%;
    height: 200px;
  }
  
  /* =========== */
  .modalBtnSection {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 20px;
  }
  .previousOrNext {
    height: 50px;
    width: 81px;
    border: 1px solid #eeeeee;
    border-radius: 4px;
    background-color: #eeeeee;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0px 17px;
  }
  
  .showDay {
    width: 125px;
    height: 50px;
    border: 1px solid #ff4300;
    border-radius: 4px;
    background-color: #ff4300;
    color: #ffffff;
    font-size: 20px;
    font-weight: 700;
    font-family: Poppins;
  }
  .calenderChange {
    width: 26px;
    height: 26px;
  }
  @media only screen and (max-width: 991px) {
    .previousOrNext {
      height: 45px;
      width: 72px;
      border: 1px solid #eeeeee;
      border-radius: 4px;
      background-color: #eeeeee;
    }
    .showDay {
      width: 112px;
      height: 45px;
      border: 1px solid #ff4300;
      border-radius: 4px;
      background-color: #ff4300;
      color: #ffffff;
      font-size: 20px;
      font-weight: 700;
      font-family: Poppins;
    }
    .timeAndPace {
      flex-direction: column;
      margin-top: 10px;
    }
    .pace {
      margin-left: 0px;
      margin-top: -15px;
    }
  }
  