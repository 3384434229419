.devider {
  width: 100%;
  height: 1px;
  background: #c1c1c1;
  margin: 20px 0px 20px 0px;
}
.userName {
  height: 58px;
  width: 100%;
  border-radius: 4px;
  background-color: #404040;
  display: flex;
  align-items: center;
  padding: 0px 10px;
}
.userName img {
  width: 46px;
  height: 46px;
  border-radius: 50%;
}

.userName p {
  font-family: Manrope;
  font-weight: 600;
  font-size: 15px;
  color: #ffffff;
  margin: 0px 0px 0px 10px;
  text-transform: capitalize;
}

.scheduleHeader {
  height: 52px;
  width: 100%;
  padding: 0px 10px;
  border: 1px solid #000000;
  border-radius: 5px;
  background-color: #000000;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
}
.scheduleBoxOne {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 90%;
}
.iconHolder {
  width: 28px;
  height: 28px;
  border-radius: 50%;
  background-color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
}
.scheduleDate p {
  font-family: Manrope;
  font-weight: 300;
  font-size: 18px;
  line-height: 19px;
  color: #ffffff;
  margin: 0;
}
.scheduleDate span {
  font-family: Manrope;
  font-weight: 600;
  font-size: 18px;
  line-height: 19px;
  color: #ffffff;
  margin: 0;
}
.scheduleInfoBox {
  height: 441px;
  width: 100%;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
  border: 1px solid #b5b5b5;
  background-color: #ffffff;
  margin: 20px 0px 0px 0px;
  padding: 15px 0px 0px 0px;
}
.typeAndLevelBox {
  height: 60px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}
.typeAndLevelBoxLabel p {
  font-family: Manrope;
  font-size: 17px;
  font-weight: 400;
  color: #000000;
  margin: 0;
}
.typeAndLevelBoxLabel span {
  font-family: Manrope;
  font-size: 17px;
  font-weight: 700;
  color: #000000;
  margin: 0;
}
.typeAndLevelBoxText p {
  font-family: Manrope;
  font-weight: 600;
  font-size: 17px;
  color: #ff4300;
  margin: 0;
}
.workoutNotes {
  display: flex;
}
.workoutNotes p {
  font-family: Manrope;
  font-weight: 500;
  font-size: 14px;
  color: #000000;
  text-align: left;
  padding: 15px 0px 0px 0px;
  margin-left: 15px;
  margin-right: 15px;
}
.scheduleInfoBoxFooter {
  width: 100%;
  height: 60px;
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
  background-color: #ececec;
  /* border: 1px solid #b5b5b5; */
  border-left: 1px solid #b5b5b5;
  border-bottom: 1px solid #b5b5b5;
  border-right: 1px solid #b5b5b5;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px 15px;
}
.scheduleInfoBoxFooter button {
  width: 92px;
  height: 34px;
  border-radius: 4px;
  background: #ffffff;
  border: 1px solid #000000;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0px 10px;
}
.scheduleInfoBoxFooter button p {
  font-family: Manrope;
  font-weight: 600;
  font-size: 14px;
  color: #000000;
  margin: 0px 0px 2px 0px;
}
.workoutDetail {
  width: 100%;
  background-color: #ffffff;
  border: 1px solid #b5b5b5;
  border-radius: 6px;
  margin: 15px 0px 0px 0px;
  padding: 20px 0px 0px 0px;
}
.workoutDetailLabel {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 15px;
}
.workoutDetailLabel p {
  font-family: Manrope;
  font-weight: 400;
  font-size: 17px;
  color: #000000;
  margin: 0px;
  padding: 0px 10px 0px 15px;
  /* line-height: 20px; */
  text-align: left;
}
.workoutDetailLabel span {
  font-family: Manrope;
  font-weight: 700;
  font-size: 17px;
  color: #000000;
  margin: 0;
}
.workoutDetailLabel h1 {
  font-family: Manrope;
  font-weight: 700;
  font-size: 17px;
  color: #ff4300;
  margin-bottom: 0px;
  word-break: break-word;
  padding: 5px 10px 5px 10px;
}
.duration {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px 50px;
}
.durationLabel {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.durationLabel p {
  font-family: Manrope;
  font-weight: 500;
  font-size: 17px;
  color: #000000;
  margin: 0px;
}
.durationLabel h1 {
  font-family: Manrope;
  font-weight: 700;
  font-size: 17px;
  color: #000000;
  margin: 0px 0px 15px 0px;
}
.workoutNotesDetail {
  padding: 0px 15px;
  margin-top: 15px;
}
.workoutNotesDetail p {
  font-family: Manrope;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #000000;
}
.coachCommentBox {
  /* height: 103px; */
  height: 100%;
  width: 100%;
  background-color: #f3f3f3;
  border-top: 1px solid #c1c1c1;
  margin-top: 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 10px 12px;
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
}
.coachCommentBox p {
  font-family: Manrope;
  font-weight: 400;
  font-size: 17px;
  color: #000000;
  margin: 0px;
}
.coachCommentBox span {
  font-family: Manrope;
  font-weight: 600;
  font-size: 17px;
  color: #000000;
}
.coachCommentBox h1 {
  font-family: Manrope;
  font-weight: 500;
  font-size: 14px;
  color: #000000;
  line-height: 20px;
  margin-top: 10px;
}
.optionBox {
  border-top: 1px solid #c1c1c1;
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
  height: 60px;
  width: 100%;
  background: #ececec;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0px 15px;
  /* margin-top: 15px; */
}
.optionBox button {
  /* width: 94px;
  height: 34px; */
  border-radius: 4px;
  padding: 0px 10px;
  background: #ffffff;
  border: 1px solid #000000;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-family: Manrope;
  font-weight: 600;
  font-size: 14px;
}

.optionBox button svg {
  margin-right: 5px;
}
.addWorkout {
  height: 60px;
  width: 100%;
  border-radius: 5px;
  background: #ffffff;
  border: 1px solid #b5b5b5;
  display: flex;
  align-items: center;
  margin-top: 15px;
  padding: 0px 15px;
}
.addWorkoutContent {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 50%;
}
.addWorkoutBtn {
  width: 126px;
  height: 34px;
  border-radius: 4px;
  border: 1px solid #ff4300;
  background: #ff4300;
  display: flex;
  justify-content: center;
  align-items: center;
}
.addWorkoutBtn p {
  font-family: Manrope;
  font-weight: 400;
  font-size: 14px;
  color: #ffffff;
  margin: 0;
}
.addWorkoutBtn span {
  font-family: Manrope;
  font-weight: 700;
  font-size: 14px;
  color: #ffffff;
  margin: 0;
}

.displayResult p {
  font-family: Manrope;
  font-weight: 600;
  font-size: 14px;
  color: #ff4300;
  margin: 0px;
}
.displayResult span {
  font-family: Manrope;
  font-weight: 600;
  font-size: 14px;
  color: #000000;
  margin: 0px;
}

.scheduleHeaderForAdd {
  height: 52px;
  width: 100%;
  padding: 0px 10px;
  border: 1px solid #ced4da;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  background-color: #ff4300;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.scheduleBoxOne {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 90%;
}
.iconHolder {
  width: 28px;
  height: 28px;
  border-radius: 50%;
  background-color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
}
.scheduleDate p {
  font-family: Manrope;
  font-weight: 300;
  font-size: 18px;
  line-height: 19px;
  color: #ffffff;
  margin: 0;
}
.scheduleDate span {
  font-family: Manrope;
  font-weight: 600;
  font-size: 18px;
  line-height: 19px;
  color: #ffffff;
  margin: 0;
}

.inputArea {
  position: relative;
  padding: 20px 20px;
}
.inputLabel p {
  font-family: Manrope;
  font-size: 16px;
  font-weight: 400;
  height: 19px;
  color: #394858;
  margin: 0;
}
.inputLabel span {
  font-family: Manrope;
  font-size: 16px;
  font-weight: 600;
  height: 19px;
  color: #394858;
  margin: 0;
}
.inputSelect {
  height: 38px;
  width: 100%;
  border: 1px solid #cdcdcd;
  border-radius: 4px;
  background: linear-gradient(180deg, #fff 0%, #ddd7d7 99.41%);
  font-family: Manrope;
  font-weight: 400;
  font-size: 15px;
  color: #000000;
}
.inputArea img {
  width: 15px;
  height: 9px;
  position: absolute;
  top: 60px;
  right: 40px;
}
.addWorkoutBox {
  margin-top: 20px;
  width: 100%;
  border: 1px solid #c1c1c1;
  border-radius: 5px;
  background: #fff;
}
.selectWorkoutBox {
  width: 100%;
  background-color: #ffffff;
  padding: 0px 10px 10px 10px;
}
.effortLevelBox {
  height: 122px;
  width: 100%;
  background: #f5f4f4;
  border-top: 1px solid #c1c1c1;
  border-bottom: 1px solid #c1c1c1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding: 15px 120px;
}
.effortLevelHead p {
  font-family: Manrope;
  font-weight: 400;
  font-size: 20px;
  color: #000000;
  margin: 0;
}
.effortLevelHead span {
  font-family: Manrope;
  font-weight: 700;
  font-size: 20px;
  color: #000000;
  margin: 0;
}
.effortLevelBtn {
  display: flex;
  justify-content: space-between;
  width: 290px;
}
.effortLevelBtnInactive {
  width: 88px;
  height: 46px;
  border-radius: 4px;
  border: 1px solid #3f3f3f;
  background-color: #f5f4f4;
  font-family: Manrope;
  font-weight: 700;
  font-size: 13px;
  color: #3f3f3f;
  text-transform: uppercase;
}

.effortLevelBtnActive {
  width: 88px;
  height: 46px;
  border-radius: 4px;
  border: 1px solid #3f3f3f;
  background-color: #3f3f3f;
  font-family: Manrope;
  font-weight: 700;
  font-size: 13px;
  color: #ffffff;
  text-transform: uppercase;
}
.checkBoxAndLabelArea {
  display: flex;
  justify-content: center;
  padding: 10px 0px;
  border-bottom: 1px solid #c1c1c1;
  background: #fff;
}
.checkbox input {
  width: 20px;
  height: 20px;
  border: 1px solid #c1c1c1;
}
.checkbox input:checked {
  width: 20px;
  height: 20px;
  border: 1px solid #c1c1c1;
  background-color: #ff4300;
}
.checkbox input:focus {
  width: 20px;
  height: 20px;
  border: 1px solid #c1c1c1;
  box-shadow: none;
  outline: none;
}
.checkBoxAndLabelArea p {
  font-family: Manrope;
  font-weight: 600;
  font-size: 18px;
  color: #000000;
  margin: 0px 0px 0px 10px;
}
.timeDurationBox {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  background: #fff;
}
.timeDurationBox p {
  font-family: Manrope;
  font-weight: 700;
  font-size: 22px;
  color: #000000;
  margin: 10px 0px 0px 0px;
}
.distanceTravel {
  display: flex;
  justify-content: center;
  margin-top: 15px;
}
.distanceTravel input {
  width: 87px;
  height: 55px;
  border-radius: 5px;
  background-color: #e9e9e9;
  border: 1px solid #e9e9e9;
  font-family: Manrope;
  font-weight: 700;
  font-size: 30px;
  color: #000000;
  text-align: center;
}

.distanceTravel input:focus-visible {
  border-color: #e9e9e9;
  box-shadow: none;
  outline: none;
}

.distanceTravel p {
  font-family: Poppins;
  font-weight: 700;
  font-size: 30px;
  color: #000000;
  margin: 10px 5px 0px 5px;
}

.timeTaken {
  display: flex;
  justify-content: center;
  margin-top: 15px;
}
.timeBox {
  position: relative;
}
.timeBox input {
  width: 76px;
  height: 75px;
  border-radius: 5px;
  background-color: #e9e9e9;
  border: 1px solid #e9e9e9;
  font-family: Manrope;
  font-weight: 700;
  font-size: 26px;
  color: #000000;
  padding-left: 13px;
  padding-right: 13px;
  padding-bottom: 27px;
  text-align: center;
}

.timeBox input:focus-visible {
  border-color: #e9e9e9;
  box-shadow: none;
  outline: none;
}

.timeTaken .timeBox p {
  font-family: Manrope;
  font-weight: 500;
  font-size: 16px;
  color: #000000;
  margin: 0;
  position: absolute;
  top: 40px;
  left: 20px;
}
.timeTaken p {
  font-family: Poppins;
  font-weight: 700;
  font-size: 30px;
  color: #000000;
  margin: 10px 5px 0px 5px;
}
.paceInfo {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 15px;
  background: #fff;
}
.paceInfo h1 {
  font-family: Manrope;
  font-weight: 700;
  font-size: 24px;
  color: #000000;
}
.paceInfo p {
  font-family: Manrope;
  font-weight: 600;
  font-size: 16px;
  color: #9e9e9e;
  margin: 0px;
  cursor: pointer;
}
.workoutNotesSection {
  margin: 15px;
}
.workoutNotesSection p {
  font-family: Manrope;
  font-weight: 400;
  font-size: 16px;
  color: #000000;
  margin-bottom: 0px;
}
.workoutNotesSection span {
  font-family: Manrope;
  font-weight: 600;
  font-size: 16px;
  color: #000000;
}
.coachCommentSection {
  width: 100%;
  background-color: #f3f3f3;
  margin-top: 20px;
  padding: 15px;
}
.coachCommentSection p {
  font-family: Manrope;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #000000;
  margin: 0;
}
.coachCommentSection span {
  font-family: Manrope;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #000000;
}
.coachCommentSection h1 {
  font-family: Manrope;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: #ff4300;
}
.btnSection {
  height: 58px;
  width: 100%;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  background: #ececec;
  border-top: 1px solid #ced4da;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px;
}
.cancelDeleteBtn {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 180px;
}
.btnSection .cancelDeleteBtn button {
  /* width: 81px;
  height: 34px; */
  border-radius: 4px;
  background: #ffffff;
  border: 1px solid #000000;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: Manrope;
  font-weight: 500;
  font-size: 14px;
  color: #000000;
}
.btnSection button {
  width: 105px;
  height: 34px;
  border-radius: 4px;
  border: 1px solid #ff4300;
  background-color: #ff4300;
  font-family: Manrope;
  font-weight: 700;
  font-size: 14px;
  color: #ffffff;
}
@media screen and (min-width: 320px) and (max-width: 575px) {
  .scheduleInfoBox {
    height: auto;
    padding: 15px 0px 10px 0px;
  }
  .typeAndLevelBox {
    height: auto;
  }
  .coachCommentBox {
    height: auto;
    padding: 10px 15px;
  }
  .addWorkoutContent {
    width: 100%;
  }
  .effortLevelBox {
    height: auto;
    padding: 15px 10px;
  }
}

.timeDurationBox .inputError {
  font-size: 15px;
  color: red;
}

.errorMsg {
  color: red;
  font-size: 0.9rem;
}

.textEditorText {
  margin-top: 6px;
  width: 100%;
  padding: 10px 12px;
  word-break: break-word;
  font-size: 14px;
  font-weight: 500;
  color: #000000;
}

.textEditorText p {
  opacity: 1;
  color: rgba(0, 0, 0, 1);
  font-family: Manrope;
  font-size: 14px !important;
  font-weight: 400 !important;
  margin: 0;
  text-align: left !important;
  word-break: break-word;
}

.noRecordFound {
  margin-top: 20px;
}
.noRecordFound p {
  opacity: 1;
  color: rgba(33, 37, 41, 1);
  font-family: Manrope;
  font-size: 16px;
  font-weight: 500;
  margin: 0;
}
.noRecordFound span {
  opacity: 1;
  color: #ff4300;
  font-family: Manrope;
  font-size: 16px;
  font-weight: 500;
  margin: 0;
  cursor: pointer;
}
