.loader {
    /* position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 9999; */
/* 
    position: fixed;
    width: 100%;
    height: 100%;
    top: 200px; */
    /* left: -40px; */
    /* bottom: 0;
    right: 233px; */
    z-index: 9999;

    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    height: 100%;
}

.loader img {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 150px;
    margin: auto;
    width: 40px;
}