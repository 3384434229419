.typeHeading {
  border-bottom: 1px solid #c1c1c1;
  padding: 0px 0px 10px 0px;
}
.typeHeading h4 {
  color: #000;
  font-size: 20px;
  font-weight: 600;
  letter-spacing: 0.46px;
  word-spacing: 4px;
}
.save button {
  width: 103px;
  height: 34px;
  background-color: #ff4300;
  border-radius: 4px;
  border: 1px solid #ff4300;
  margin-right: 10px;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
}
.textEditor {
  margin: 20px 0px 20px 0px;
}
