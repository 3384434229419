.topHead h2 {
  color: #000000;
  font-size: 21px;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 0.48px;
}

.brandTitle h2 {
  font-size: 21px;
  font-weight: 600;
  color: #000000;
  text-transform: uppercase;
  letter-spacing: 0.48px;
}

.topHead p {
  font-size: 24px;
  font-weight: 500;
  letter-spacing: 0.48px;
}

.brandOption {
  display: flex;
  justify-content: space-between;
  cursor: pointer;
}

.leftOption {
  display: flex;
}

.leftOption h3 {
  background-color: #000;
  border-radius: 50px;
  color: #fff;
  padding: 10px 20px 10px 20px;
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 0px;
}

.leftOption svg {
  color: #ff4300;
  font-size: 35px;
  font-weight: 900;
  margin-left: 21px;
}

.searchControl {
  border-radius: 4px;
  width: 100%;
  margin: 0px auto;
  border: 1px solid #c1c1c1;
  height: 38px;
  max-width: 183px;
}

.FormGroup .searchControl::placeholder {
  font-size: 14px;
  font-weight: 400;
  color: #c4c4c4;
}

.searchControl:focus {
  outline: none;
  border: 1px solid #c1c1c1;
}

.searchControl:focus {
  box-shadow: none !important;
  border: 1px solid #c1c1c1 !important;
}
.highlighted {
  background-color: #d7d7d7 !important;
}

.brandList {
  display: flex;
  justify-content: space-between;
  background-color: #fff;
  border: 1px solid #c1c1c1;
  margin-top: 20px;
  border-radius: 5px;
  padding: 14px 10px 14px 10px;
}

.leftContent {
  display: flex;
}

.nameList {
  width: 107px;
  height: 65px;
}
.nameList img {
  /* width: 100%; */
  max-width: 107px;
  height: 65px;
  object-fit: cover;
  border-radius: 5px;
}

.paraText {
  margin-left: 15px;
  padding: 6px 5px 0px 0px;
}

.brandIcon {
  margin-top: 5px;
}

.cross {
  background-color: #ff4300;
  border-radius: 5px;
  margin-bottom: 8px;
  width: 24px;
  height: 24px;
}

.cross svg {
  color: #fff;
  font-size: 12px;
  margin-left: 6px;
  margin-top: -3px;
}

.edit {
  padding: 4px 9px;
  background-color: #000;
  border-radius: 5px;
  width: 24px;
  height: 24px;
}

.edit svg {
  color: #ffffff;
  font-size: 20px;
  margin-left: -7px;
  margin-top: -10px;
}

.paraText h4 {
  color: #000;
  font-size: 13px;
  font-weight: 600;
  text-transform: capitalize;
  margin-bottom: 2px;
  word-break: break-word;
  
}

.paraText p {
  color: #000000;
  font-size: 12px;
  font-weight: 300;
  margin-bottom: 0px;
  letter-spacing: 0.24px;
  word-break: break-word;
}

.paraText span {
  color: #ff4300;
  font-size: 12px;
  font-weight: 600;
}

.eventBox {
  background-color: #fff;
  border: 1px solid #c1c1c1;
  margin-top: 25px;
  border-radius: 5px;
  height: 60px;
}

.eventText p {
  color: #ff4300;
  font-size: 14px;
  font-weight: 600;
  margin-top: 6px;
}

.eventText span {
  color: #000;
  font-size: 14px;
  font-weight: 600;
}

.addBtn button {
  padding: 0px 25px;
  height: 34px;
}

.addBtn button span {
  font-size: 14px !important;
}

.loadMore {
  margin-left: 30px;
}

.loadMore button {
  padding: 0px 25px;
  height: 34px;
}

.loadMore button p {
  text-align: center;
  color: #fff;
  font-size: 14px !important;
  font-weight: 400;
  margin-bottom: 0px;
}

.loadMore button span {
  text-align: center;
  color: #fff;
  font-size: 14px !important;
  font-weight: 700;
}

/*---------------------- Brand info tab css ----------*/

.brandDetailInfo {
  margin-top: 15px;
  border-top: 1px solid #c1c1c1;
  padding: 15px 0px;
  border-bottom: 1px solid #c1c1c1;
}

.brandDetailInfo p {
  color: #000;
  font-size: 20px;
  font-weight: 400;
  margin-bottom: 10px;
}

.brandDetailInfo span {
  color: #000;
  font-size: 20px;
  font-weight: 700;
}

.brandDetailInfo h3 {
  color: #000000;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0.32px;
  margin-bottom: 0px;
}

.clublabelText p {
  color: #394858;
  font-size: 16px;
  font-weight: 400;
  margin-bottom: 0px;
}

.clublabelText span {
  color: #394858;
  font-size: 16px;
  font-weight: 600;
}

.labelText p {
  color: #394858;
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 0px;
}

.labelText span {
  color: #394858;
  font-size: 16px;
  font-weight: 400;
}

.location {
  border-bottom: 1px solid #c1c1c1;
  padding-bottom: 10px;
}

.CountrylabelText {
  color: #394858;
  font-size: 14px;
  font-weight: 500;
}
.phycicalAddress {
  margin-top: 20px;
}

.phycicalAddress p {
 font-size: 16px !important;
 font-weight: 600 !important;
 color: #394858 !important;
 margin-bottom: 0px !important;
}

.phycicalAddress span {
  font-size: 16px !important;
 font-weight: 400 !important;
 color: #394858 !important;
}


.memberLimit .inputIcon .FormInput,
.inputIcon select {
  padding: 0px 10px;
  height: 40px;
  font-size: 15px;
  width: 100%;
  max-width: 300px;
  border-radius: 4px;
  background-color: #fff !important;
  color: #000 !important;
  font-weight: 400;
  line-height: 1.6 !important;
  touch-action: manipulation;
  border: 1px solid #c1c1c1;
}

.clubName .inputIcon .FormInput,
.clubName .inputIcon select:focus {
  outline: none;
  border: none;
  box-shadow: none;
}

.brandText {
  padding-bottom: 15px;
  border-bottom: 1px solid #c1c1c1;
}
.editP p {
  font-size: 16px;
  font-weight: 400;
  color: #394858;
  margin-top: 10px;
  margin-bottom: 10px;
}

.aboutMember span {
  font-size: 16px;
  font-weight: 600;
  color: #394858;
}

.location P {
  color: #394858;
  font-size: 16px;
  font-weight: 600;
  margin-top: 15px;
}

.location span {
  color: #394858;
  font-size: 16px;
  font-weight: 400;
}

.featureImg {
  border-top: 1px solid #c1c1c1;
  border-bottom: 1px solid #c1c1c1;
}

.featureImg h2 {
  color: #394858;
  font-size: 15px;
  font-weight: 500;
}

.featureImg p {
  color: #ff4300;
  font-size: 14px;
  font-weight: 500;
}
/*--------- brand info .cross -------------*/
.devider {
  width: 100%;
  height: 1px;
  background: #c1c1c1;
  margin: 15px 0px;
}

.brandHeading h3 {
  color: #000;
  font-size: 20px;
  font-weight: 600;
  text-transform: uppercase;
  padding: 5px 5px;
  /* border-bottom: 1px solid #c1c1c1; */
}

.btnStyleMatch p {
  color: #394858;
  font-size: 16px;
  font-weight: 600;
}

.btnStyleMatch span {
  color: #394858bf;
  font-size: 16px;
  font-weight: 400;
  padding: 15px 5px;
}

.btnLabel p {
  color: #394858;
  font-size: 16px;
  font-weight: 400;
  margin-bottom: 3px;
}
.btnLabel span {
  color: #394858;
  font-size: 16px;
  font-weight: 600;
}

.dispalyColor {
  display: flex;
  justify-content: space-between;
}
.inputBox {
  margin-left: 12px;
}

.inputBox input {
  height: 38px;
  width: 122px;
  border: 1px solid #c4c4c4;
}

.inputBox input:focus {
  box-shadow: none;
  border: 1px solid #c4c4c4;
  outline: none;
}
.colourInput {
  display: flex;
}

.bottomBox P {
  color: #394858;
  font-size: 16px !important;
  font-weight: 400 !important;
  margin-bottom: 3px;
}

.bottomBox span {
  color: #394858;
  font-size: 16px !important;
  font-weight: 600 !important;
}

.brandBgText {
  color: #ff4300 !important;
  font-size: 14px;
  font-weight: 600;
}

.clorInput .bottomHighlight {
  color: #ff4300;
  margin-bottom: 10px;
  margin-top: 4px;
  font-size: 14px;
  font-weight: 600;
}

.exBtn .exPara {
  color: #ff4300;
  margin-bottom: 10px;
  margin-top: 4px;
  font-size: 14px;
  font-weight: 600;
}

.exBtnBtn button {
  border-radius: 4px;
  height: 48px;
  padding: 0px 30px;
  width: 226px;
}

.exBtnBtn button p {
  font-size: 17px !important;
  font-weight: 900 !important;
  text-transform: uppercase;
  margin-bottom: 0px;
}

.exBtnBtn button span {
  font-size: 17px !important;
  font-weight: 300 !important;
  text-transform: uppercase;
}

.colorBtn button {
  border-radius: 4px;
  height: 48px;
  padding: 0px 30px;
  width: 226px;
}
.colorBtn p {
  font-size: 17px !important;
  font-weight: 900 !important;
  margin-bottom: 0px;
}

.colorBtn span {
  font-size: 17px !important;
  font-weight: 300 !important;
}
.tabBar P {
  color: #394858;
  font-size: 16px;
  font-weight: 400;
  margin-bottom: 0px;
  margin-top: 12px;
}

.tabBar span {
  color: #394858;
  font-size: 16px;
  font-weight: 600;
}

.tabBox {
  border: 1px solid #c1c1c1;
  background-color: #404040;
  margin-top: 12px;
  border-radius: 10px;
  padding: 15px 0px 20px 0px;
}

.menuItem {
  display: flex;
  justify-content: space-evenly;
}

.menuItem svg {
  color: #fff;
  font-size: 25px;
  font-weight: 600;
}

.calendersvg {
  background-color: #ff4300;
  border-radius: 50px;
  padding: 8px 10px;
}

.calendersvgs {
  background-color: #404040;
  border-radius: 50px;
  padding: 8px 10px;
}

.calendersvg svg {
  color: #fff;
  font-size: 25px !important;
  font-weight: 600;
}

.resetDefault p {
  color: #000;
  font-size: 14px;
  font-weight: 900;
}

.resetDefault span {
  color: #000;
  font-size: 14px;
  font-weight: 400;
}

.resetDefault {
  margin-top: 20px;
  border-bottom: 1px solid #c1c1c1;
}

.bottomText {
  margin-top: 20px;
}

.bottomText h5 {
  font-size: 14px;
  font-weight: 500;
  color: #000;
}

.bottomText p {
  font-size: 16px;
  font-weight: 600;
  color: #394858;
}

.bottomText span {
  font-size: 16px;
  font-weight: 400;
  color: #394858;
}
.uploadLabel button {
  margin-bottom: 10px;
  height: 34px;
  padding: 0px 20px;
  border-radius: 4px;
}

.uploadLabel span {
  letter-spacing: 0.4px !important;
  text-transform: capitalize !important;
  font-size: 14px !important;
  font-weight: 500 !important;
}
.uploadBox button {
  margin-bottom: 20px;
  height: 34px;
  padding: 0px 20px;
  border-radius: 4px;
}

.uploadBox label {
  text-align: center;
  padding: 10px 30px;
  background-color: #ff4300;
  border-radius: 7px;
  color: #fff;
  border: 1px solid #ff4300;
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 20px;
}
.googleMap {
  margin-top: 15px;
}
.googleMap .dragLocation {
  color: #ff4300;
  font-size: 14px;
  font-weight: 500;
  margin-top: 10px;
}

.membershipImage {
  border-top: 1px solid #c1c1c1;
  border-bottom: 1px solid #c1c1c1;
  padding-bottom: 10px;
}

.membershipImage h3 {
  color: #394858;
  font-size: 16px;
  font-weight: 400;
  margin-top: 15px;
  margin-bottom: 2px;
}

.membershipImage p {
  color: #ff4300;
  font-size: 14px !important;
  font-weight: 500 !important;
}

.imgUpload {
  /* border: 1px solid #c1c1c1;
  margin-top: 15px;
  position: relative;
  width: 100%;
  max-width: 270px;
  object-fit: cover;
  padding: 30px; */

  align-items: center;
    border: 1px solid #c1c1c1;
    border-radius: 6.32px;
    display: flex;
    height: 140px;
    justify-content: center;
    margin-top: 20px;
    padding: 30px;
    position: relative;
    width: 230px;
}

.imgUpload img {
  width: 100%;
  max-width: 181px;
  height: 110px;
  object-fit: cover;
}

.imgUploadInfo {
  border: 1px solid #c1c1c1;
  margin-top: 15px;
  position: relative;
  width: 100%;
  max-width: 170px;
  object-fit: cover;
  padding: 3px;
}

.imgUploadInfo img {
  width: 100%;
  max-width: 170px;
  height: 150px;
  object-fit: cover;
}
.addButton {
  margin-top: 15px;
  /* border-bottom: 1px solid #c1c1c1;
  padding-bottom: 10px; */
}

.addButton p {
  color: #ff4300;
  font-size: 14px;
  font-weight: 500;
}

.btnSection {
  display: flex;
  justify-content: space-between;
  margin-top: 12px;
  padding: 0px 10px;
}

.BrandingbtnSection {
  display: flex;
  justify-content: space-between;
  margin-top: 12px;
  padding: 0px 0px;
}


.closeIcon {
  font-size: 25px;
  position: absolute;
  right: 0px;
  top: 0px;
  color: #fff;
  background-color: #ff4300;
  font-weight: 600;
  border-radius: 5px;
}

.deletBtn button {
  text-align: center;
  padding: 10px 0px;
  background-color: #fff;
  border-radius: 7px;
  color: #000;
  border: 1px solid #333;
  font-size: 14px;
  font-weight: 600;
  width: 161px;
}
.deletBtn {
  margin-left: 15px;
}

.deletBtn svg {
  font-size: 18px;
  font-weight: 600;
  margin-top: -4px;
}
.resetColor {
  margin-top: 10px;
}

.resetColor p {
  color: #394858;
  font-size: 16px;
  font-weight: 600;
}
.resetColor span {
  color: #394858;
  font-size: 16px;
  font-weight: 400;
  padding: 20px 10px;
}

.exbtnLabel p {
  font-size: 16px;
  font-weight: 400;
  color: #394858;
  margin-bottom: 3px;
}

.exbtnLabel span {
  font-size: 16px;
  font-weight: 600;
  color: #394858;
}

/* ---------subscription tab css----------- */
.subscription {
  border: 1px solid #c1c1c1;
  background-color: #404040;
  margin-top: 12px;
  border-radius: 5px;
  /* height: 58px; */
  min-height: 58px;
}

.subscription h2 {
  color: #fff;
  font-size: 15px;
  font-weight: 600;
  margin-bottom: 0px;
  /* padding: 10px 5px 10px 5px; */
  padding: 17px 10px 10px 10px;
  text-align: left;
  text-transform: capitalize;
  word-break: break-word;
}

.currentStatus {
  margin-top: 15px;
  border-top: 1px solid #c1c1c1;
  border-bottom: 1px solid #c1c1c1;
}

.currentStatus h3 {
  color: #000000;
  font-size: 20px;
  font-weight: 600;
  padding: 20px 0px 20px 0px;
  text-transform: uppercase;
  letter-spacing: 1.5px;
  margin-bottom: 0px;
}

/*------------- input icon css-------------- */
.inputIcon {
  position: relative;
  margin-bottom: 22px;
}

.Icon {
  color: #958b8b;
  top: 47px;
  left: 10px;
  font-size: 18px;
  position: absolute;
  cursor: pointer;
}

.Icon svg {
  font-size: 22px;
  position: absolute;
  bottom: 11px;
  color: #727272;
  cursor: pointer;
}

/*------------- form input css----------- */
.inputIcon .FormInput,
.inputIcon select {
  padding: 0px 10px;
  height: 48px;
  font-size: 15px;
  width: 100%;
  border-radius: 4px;
  background-color: #fff !important;
  color: #000 !important;
  font-weight: 400;
  line-height: 1.6 !important;
  touch-action: manipulation;
  border: 1px solid #c1c1c1 !important;
}

.inputIcon .FormInput,
.inputIcon select:focus {
  outline: none;
  border: none;
  box-shadow: none;
}

.inputIcon .FormInput,
.inputIcon ::placeholder {
  color: #727272;
  width: 100%;
  border-radius: 4px;
}

.inputIcon label {
  font-size: 14px;
  font-weight: 500;
  color: #394858;
}

/*------------- input icon css----------- */
.inputIcon {
  position: relative;
  margin-bottom: 10px;
  margin-top: 10px;
}

.lockIcon {
  color: #958b8b;
  top: 47px;
  font-size: 18px;
  position: absolute;
  cursor: pointer;
}

.lockIcon svg {
  font-size: 22px;
  position: absolute;
  color: #727272;
  cursor: pointer;
  left: 465px;
}

.switchOn h3 {
  font-size: 16px;
  font-weight: 500;
  color: #000000;
}

.switchOn {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}

.memeberActive {
  border-bottom: 1px solid #c1c1c1;
}
.memeberActive p {
  color: #000000;
  font-size: 13px;
  font-weight: 500;
}

.btnSectionInfo {
  background-color: #fff;
  border-radius: 5px;
  justify-content: space-between;
  margin-top: 20px;
  height: 60px;
}

.saveChangesBtn {
  background-color: #fff;
  border-radius: 5px;
  display: flex;
  justify-content: end;
  margin-top: 20px;
  height: 60px;
}

.Changes button {
  padding: 0px 30px;
  height: 34px;
  border-radius: 4px;
}

.Changes {
  padding: 15px 10px;
}

.saveBtnSection {
  background-color: #fff;
  border-radius: 5px;
  margin-top: 20px;
  height: 60px;
}

.saveChanges button {
  text-align: center;
  padding: 8px 35px;
  background-color: #ff4300;
  border-radius: 7px;
  width: 100%;
  color: #fff;
  border: 1px solid #ff4300;
  font-size: 17px;
  font-weight: 600;
}

.deleteBtn {
  border: 1px solid #000;
}
.saveBtn {
  display: flex;
  justify-content: end;
  padding: 12px 10px;
}

.saveBtn button {
  text-align: center;
  background-color: #ff4300;
  border-radius: 7px;
  width: 106px;
  height: 34px;
  color: #fff;
  border: 1px solid #ff4300;
  font-size: 17px;
  font-weight: 600;
}

.noResultsMessage {
  color: #ff4300;
  text-align: center;
  font-size: 18px;
  margin-top: 20px;
}

/*-------- Add media query for manage responsive --------*/
@media screen and (max-width: 359px) {
  .uploadLabel {
    padding: 10px 30px;
    margin-bottom: 20px;
    margin-right: 12px;
  }

  .deletBtn button {
    padding: 12px 12px !important;
    font-size: 12px !important;
  }

  .addBtn button p {
    font-size: 10px;
  }

  .addBtn button span {
    font-size: 12px;
  }
  .loadMore button p {
    font-size: 10px;
  }

  .loadMore button span {
    font-size: 12px;
  }

  .addBtn button {
    padding: 0px 8px !important;
  }

  .loadMore {
    margin-left: 8px !important;
  }

  .loadMore button {
    padding: 0px 8px !important;
  }

  .eventBox {
    margin-top: 20px;
    padding: 5px 10px;
  }

  .eventText p {
    font-size: 14px;
    margin-top: 12px;
  }

  .eventText span {
    font-size: 12px;
  }

  .btnSection {
    margin-top: 5px !important;
  }
}
@media screen and (max-width: 575px) {
  .editorBox {
    padding-bottom: 2px;
  }
  .uploadLabel {
    padding: 10px 30px;
    margin-bottom: 20px;
    margin-right: 12px;
  }

  .deletBtn button {
    padding: 12px 12px !important;
    font-size: 12px !important;
  }

  .brandList {
    padding: 10px 10px;
  }
  .paraText {
    margin-left: 10px;
    padding: 0px 0px;
    margin-top: 5px;
  }

  .paraText h4 {
    font-size: 10px;
  }

  .paraText p {
    font-size: 10px;
    margin-bottom: 0px;
  }

  .paraText span {
    font-size: 7px;
  }

  .cross {
    padding: 4px 8px;
  }

  .cross svg {
    font-size: 15px;
    margin-left: 1px;
    margin-top: -2px;
  }

  .edit {
    padding: 4px 8px;
  }

  .edit svg {
    font-size: 15px;
    margin-left: 1px;
    margin-top: -2px;
  }

  .addBtn button {
    padding: 5px 8px;
    font-size: 9px;
  }

  .loadMore {
    margin-left: 20px;
  }

  .loadMore button {
    padding: 5px 8px;
    font-size: 9px;
  }

  .eventBox {
    margin-top: 20px;
    padding: 5px 10px;
  }

  .eventText p {
    font-size: 14px;
    margin-top: 12px;
  }

  .eventText span {
    font-size: 12px;
  }

  .exBtnBtn button {
    padding: 10px 12px;
    margin-left: 10px;
    font-size: 13px;
  }

  .colorBtn button {
    padding: 10px 12px;
    margin-left: 10px;
    font-size: 13px;
  }

  .colorBtn p {
    font-size: 15px;
  }

  .colorBtn span {
    font-size: 15px;
  }

  .deletBtn {
    margin-left: 0px;
  }
  .deletBtn svg {
    font-size: 15px;
  }

  .leftOption svg {
    margin-right: 10px;
    margin-left: 12px;
  }
  .btnSection {
    margin-top: 5px !important;
  }
}

.selectInput {
  min-height: 75px;
  margin-top: 10px;
  position: relative;
}
.selectInput label p {
  opacity: 1;
  color: #394858;
  font-family: Manrope;
  font-size: 14.5px;
  font-weight: 500;
  margin: 0;
}
.selectInput select {
  width: 278px;
  height: 38px;
  border-radius: 4px;
  border: 1px solid #c4c4c4;
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 1) 0%,
    rgba(236, 236, 236, 1) 100%
  );
  opacity: 1;
  color: rgba(108, 117, 125, 1);
  font-family: Manrope;
  font-size: 15px;
  font-weight: 500;
}
.selectInput svg {
  position: absolute;
  top: 35px;
  left: 240px;
  font-size: 30px;
}

.errorInput {
  border: 1px solid red !important;
}

.bottomBtnSection {
  display: flex;
  justify-content: space-between;
  padding: 15px 10px;
}

.saveInfo button {
  padding: 0px 30px;
  height: 34px;
  border-radius: 4px;
}

/* ==========branding css ===============*/

.uploadBox button p {
  opacity: 1;
  color: rgba(255, 255, 255, 1);
  font-family: Manrope;
  font-size: 14px;
  font-weight: 400;
  margin: 0;
}
.uploadBox button span {
  opacity: 1;
  color: rgba(255, 255, 255, 1);
  font-family: Manrope;
  font-size: 14px;
  font-weight: 600;
  margin: 0;
}

.previewImgBox {
  width: 270px;
  height: 110px;
  position: relative;
  border-radius: 6.32px;
  margin-top: 20px;
}
.previewImg {
  width: 270px;
  height: 110px;
  border-radius: 6.32px;
}
.removeImg {
  position: absolute;
  width: 24px;
  height: 24px;
  right: 0;
  cursor: pointer;
}

.uploadBox button p {
  opacity: 1;
  color: rgba(255, 255, 255, 1);
  font-family: Manrope;
  font-size: 14px;
  font-weight: 400;
  margin: 0;
}
.uploadBox button span {
  opacity: 1;
  color: rgba(255, 255, 255, 1);
  font-family: Manrope;
  font-size: 14px;
  font-weight: 600;
  margin: 0;
}

.brandingError .errorMsgBranding {
  color: red !important;
  font-size: 12px !important;
}

/*-------------- add brand css------------ */

.addBrand {
  border: 1px solid #ff4300;
  background-color: #ff4300;
  margin-top: 15px;
  border-radius: 5px;
  height: 58px;
  margin-bottom: 15px;
}

.addBrand h2 {
  color: #ffffff;
  font-size: 15px;
  font-weight: 700;
  margin-bottom: 0px;
  padding: 19px 0px 17px 10px;
  text-align: left;
  text-transform: uppercase;
}

.addBorder {
  border-bottom: 1px solid #c1c1c1;
}

.addTitle h2 {
  color: #000000;
  font-size: 24px;
  font-weight: 500;
  letter-spacing: 0.48px;
}
.emailLabelText p {
  color: #394858;
  font-size: 15px !important;
  font-weight: 500 !important;
  margin-top: 0px !important;
}

.loginEmail p {
  color: #394858;
  font-size: 16px;
  font-weight: 600;
  margin-top: 10px;
  margin-bottom: 0px;
}

.loginEmail span {
  color: #394858;
  font-size: 16px;
  font-weight: 400;
}

.cancelBtn button {
  width: 81px;
  height: 34px;
  border-radius: 4px;
  padding: 0px 10px;
  background: #ffffff;
  border: 1px solid #000000;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  font-family: Manrope;
  font-weight: 500;
  font-size: 14px;
}

.saveAddBtn button {
  text-align: center;
  width: 131px;
  height: 34px;
  background-color: #ff4300;
  border-radius: 7px;
  color: #fff;
  border: 1px solid #ff4300;
  font-size: 17px;
  font-weight: 600;
}

.editCross {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

.editCross {
  height: 60px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.editCross img {
  width: 24px;
  height: 24px;
}

.brandEmail{
  opacity: 1;
  color: rgba(57, 72, 88, 1);
  font-family: Manrope;
  font-size: 16px !important;
  font-weight: 600 !important;
  margin: 0;
}

.emailSpan{
  opacity: 1;
  color: rgba(57, 72, 88, 1);
  font-family: Manrope;
  font-size: 16px !important;
  font-weight: 400 !important;
  margin: 0;
}

.townCityLabel{
  opacity: 1;
  color: #394858 !important;
  font-family: Manrope;
  font-size: 14.5px !important;
  font-weight: 500 !important;
  margin: 0;
}
