.devider {
  width: 100%;
  height: 1px;
  background: #c1c1c1;
  margin: 15px 0px 15px 0px;
}

.topHead h2 {
  color: #000000;
  font-size: 21px;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 0.48px;
}

.heading {
  font-size: 20px;
  font-weight: 600;
  letter-spacing: 0.46px;
  color: #000000;
  margin: 0;
  text-transform: uppercase;
}

.events {
  width: 88px;
  height: 35px;
  background: #000000;
  border-radius: 18px;
  border: 1px solid #000000;
  font-family: Manrope;
  font-size: 14.4px;
  font-weight: 500;
  color: #ffffff;
  margin-right: 12px;
}

.searchControl {
  border-radius: 4px;
  width: 100%;
  margin: 0px auto;
  border: 1px solid #c1c1c1;
  height: 38px;
  max-width: 183px;
}

.FormGroup .searchControl::placeholder {
  font-size: 14px;
  font-weight: 400;
  color: #c4c4c4;
}

.searchControl:focus {
  outline: none;
  border: 1px solid #c1c1c1;
}

.searchControl:focus {
  box-shadow: none !important;
  border: 1px solid #c1c1c1 !important;
}

.calender {
  width: 88px;
  height: 35px;
  background: #ffffff;
  border-radius: 18px;
  border: 1px solid #ffffff;
  font-family: Manrope;
  font-size: 14.4px;
  font-weight: 500;
  color: #000000;
}

.option {
  width: 100%;
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #c1c1c1;
  padding: 0px 0px 15px 0px;
}

.buttonSection {
  width: 300px;
  display: flex;
  gap: 10;
}

.invites {
  width: 110px;
  height: 40px;
  background: #ffffff;
  border-radius: 20px;
  border: 1px solid #ffffff;
  font-size: 14.4px;
  font-weight: 500;
  color: #000000;
  margin-right: 12px;
}
.search {
  width: 183px;
  height: 38px;
  background: #ffffff;
  border: 1px solid #c4c4c4;
  border-radius: 4px;
  padding: 0px 10px;
}

/*--------- event detail css----------- */

.summary {
  width: 100%;
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #c1c1c1;
  padding: 10px 0px 10px 0px;
}

.personalDetail {
  display: flex;
  padding: 6px 0px 6px 0px;
}
.personalDetail p {
  font-size: 13px;
  font-weight: 400;
  line-height: 17.76px;
  color: #000000;
  margin: 0;
}

.eventBox {
  background-color: #fff;
  border: 1px solid #c1c1c1;
  margin-top: 25px;
  padding: 0px 8px;
  border-radius: 5px;
  height: 60px;
}

.eventText p {
  color: #ff4300;
  font-size: 14px;
  font-weight: 600;
  margin-top: 8px;
}

.eventText span {
  color: #000;
  font-size: 14px;
  font-weight: 600;
}

.btnSection {
  display: flex;
  justify-content: space-between;
  /* margin-top: 10px; */
  padding: 12px 5px 0px 5px;
}

.personalDetail h1 {
  /* font-family: Manrope; */
  font-size: 13px;
  font-weight: 700;
  line-height: 17.76px;
  color: #000000;
  margin: 0px 4px;
}

.addBtn button {
  padding: 0px 25px;
  height: 34px;
  border-radius: 4px;
  margin-right: 10px;
}

.addBtn button p {
  text-align: center;
  color: #fff;
  font-size: 12px;
  font-weight: 400;
  text-transform: uppercase;
  margin-bottom: 0px;
}

.addBtn button span {
  text-align: center;
  color: #fff;
  font-size: 12px;
  font-weight: 700;
  text-transform: uppercase;
}

.loadMore {
  margin-left: 30px;
}

.loadMore button {
  padding: 0px 25px;
  height: 34px;
  border-radius: 4px;
}

.loadMore button p {
  text-align: center;
  color: #fff;
  font-size: 12px;
  font-weight: 400;
  text-transform: uppercase;
  margin-bottom: 0px;
}

.loadMore button span {
  text-align: center;
  color: #fff;
  /* font-size: 12px; */
  font-weight: 700;
  text-transform: uppercase;
}

/* calender css */
.calendersvg {
  background-color: #fff;
  border-radius: 50px;
  width: 38px;
  height: 38px;
  margin-right: 12px;
}

.calendersvg button {
  margin-left: 8px;
  margin-top: 7px;
}

.eventDetail {
  display: flex;
  margin-top: 15px;
}

.eventDetail p {
  color: #212529;
  font-size: 16px;
  font-weight: 400;
  margin-bottom: 0px;
}

.eventDetail h3 {
  color: #ff4300;
  font-size: 16px;
  font-weight: 700;
  margin-right: 21px;
  margin-left: 12px;
  margin-bottom: 0px;
  margin-top: 4px;
  cursor: pointer;
}

.eventDetail span {
  color: #212529;
  font-size: 16px;
  font-weight: 700;
}
@media only screen and (max-width: 991px) {
  .previousOrNext {
    height: 45px;
    width: 72px;
    border: 1px solid #ff4300;
    border-radius: 4px;
    background-color: #ff4300;
  }
}

/*--------- Add event page css-------- */

.addeventBox {
  padding: 12px 0px 12px 0px;
  border-bottom: 1px solid #c1c1c1;
}

.newEventBox {
  border: 1px solid #ff4300;
  background-color: #ff4300;
  /* margin-top: 12px; */
  padding: 20px 15px 20px 12px;
  border-radius: 5px;
}

.newEventBox p {
  color: #ffffff;
  font-size: 15px;
  font-weight: 900;
  margin-bottom: 0px;
}

.newEventBox span {
  color: #fff;
  font-size: 15px;
  font-weight: 600;
  margin-bottom: 0px;
}

.eventTitle {
  border-bottom: 1px solid #c1c1c1;
  padding: 15px 0px 15px 0px;
}

.inputIcon .FormInput,
.inputIcon select {
  padding: 0px 10px;
  height: 48px;
  font-size: 15px;
  width: 100%;
  /* max-width: 500px; */
  border-radius: 4px;
  background-color: #fff !important;
  color: #000 !important;
  font-weight: 400;
  line-height: 1.6 !important;
  touch-action: manipulation;
}

.inputIcon .FormInput,
.inputIcon select:focus {
  outline: none;
  border: none;
  box-shadow: none;
}

.inputIcon .FormInput,
.inputIcon ::placeholder {
  color: #727272;
  width: 100%;
  border-radius: 4px;
}

.inputIcon label {
  font-size: 14px;
  font-weight: 500;
  color: #394858;
}

.labelText p {
  color: #394858;
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 0px;
  margin-bottom: 0px;
  margin-top: 10px;
}

.labelText span {
  color: #394858;
  font-size: 16px;
  font-weight: 400;
}
.checkDisplay {
  display: flex;
  padding: 15px 0px 15px 0px;
  border-bottom: 1px solid #c1c1c1;
}
.showDate {
  display: flex;
  justify-content: space-between;
}

.datePicker {
  padding: 10px 20px 10px 31px !important;
  width: 220px;
  height: 38px;
  border: 1px solid #c1c1c1;
}

/* .timedatePicker{
  padding: 10px 20px 10px 5px !important;
  border: 1px solid #c1c1c1;
  position: relative;

} */

.googleMap .dragLocation {
  color: #ff4300;
  font-size: 14px;
  font-weight: 500;
  margin-top: 10px;
}

.selectDate {
  border-bottom: 1px solid #c1c1c1;
  padding-bottom: 15px;
}

.selectDate p {
  color: #394858;
  font-size: 15.5px;
  font-weight: 600;
}

.selectDate span {
  color: #394858;
  font-size: 15.5px;
  font-weight: 500;
}

.eventLOcation {
  padding-block-start: 10px;
  border-bottom: 1px solid #c1c1c1;
}
.infoLabel {
  border-bottom: 1px solid #c1c1c1;
  padding: 12px 0px 52px 0px;
}
.infoLabel h2 {
  color: #394858;
  font-size: 16px;
  font-weight: 400;
  /* margin-top: 12px; */
}

.eventImage {
  padding: 10px 0px 10px 0px;
  border-bottom: 1px solid #c1c1c1;
}

.eventImage h4 {
  color: #394858;
  font-size: 16px;
  font-weight: 400;
}

.eventImage p {
  color: #ff4300;
  font-size: 14px;
  font-weight: 500;
}

.uploadLabel {
  text-align: center;
  padding: 10px 30px;
  background-color: #ff4300;
  border-radius: 7px;
  color: #fff;
  border: 1px solid #ff4300;
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 20px;
}

.imgUpload {
  border: 1px solid #c1c1c1;
  margin-top: 15px;
  position: relative;
  width: 100%;
  max-width: 270px;
  object-fit: cover;
  padding: 30px;
}

.imgUpload img {
  width: 100%;
  max-width: 270px;
  height: 110px;
  object-fit: cover;
}

.closeIcon {
  font-size: 25px;
  position: absolute;
  right: -3px;
  top: -6px;
  color: #fff;
  background-color: #ff4300;
  font-weight: 600;
  border-radius: 5px;
}
.adBtn {
  padding: 10px 0px 10px 0px;
  border-bottom: 1px solid #c1c1c1;
}
.adBtn p {
  color: #ff4300;
  font-size: 14px;
  font-weight: 500;
}

.includeResult {
  padding: 10px 0px 10px 0px;
}

.includeResult p {
  color: #ff4300;
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 0px;
}

/* .addSection {
  padding: 30px 35px;
} */

.addEventDate {
  position: relative;
}

.addEventDate svg {
  position: absolute;
  left: 7px;
  top: 8px;
  font-size: 24px;
  color: #000;
  font-weight: 600;
}
.timedatePicker {
  width: 220px;
  height: 38px;
  border-radius: 4px;
  border: 1px solid #c4c4c4;
  background: #f6f6f6;
  font-family: Manrope;
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  color: #000000;
  padding-left: 40px;
}
.timedatePicker::placeholder {
  font-family: Manrope;
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  color: #000000;
}

.pushNotification {
  padding: 20px 0px 20px 0px;
  border-bottom: 1px solid #c1c1c1;
}

.notifyHeading h5 {
  color: #394858;
  font-size: 15px;
  font-weight: 500;
  margin-bottom: 0px;
}

.notifyHeading p {
  color: #ff4300;
  font-size: 14px;
  font-weight: 500;
}

.limit p {
  font-size: 16px;
  font-weight: 400;
  color: #394858;
}
.limit span {
  font-size: 16px;
  font-weight: 300;
  color: #394858;
}

.titleText {
  color: #6c757d;
  font-size: 15px;
  font-weight: 400;
}

.pushBtn button {
  color: #696969;
  font-size: 14px;
  font-weight: 500;
  padding: 12px 15px 12px 15px;
  border: 1px solid #c1c1c1;
}

.activeEventSection {
  border-bottom: 1px solid #c1c1c1;
}

.switchOn h3 {
  font-size: 16px;
  font-weight: 500;
  color: #000000;
  margin-bottom: 0px;
}

.switchOn {
  display: flex;
  justify-content: space-between;
  margin-top: 15px;
}

.activeEventSection p {
  color: #ff4300;
  font-size: 14px;
  font-weight: 500;
}
.eventBtn {
  display: flex;
  justify-content: space-between;
  background-color: #ffff;
  margin-top: 20px;
  border-radius: 4px;
  height: 60px;
  padding: 13px 10px;
}

.cancel button {
  width: 81px;
  height: 34px;
  font-size: 14px;
  font-weight: 500;
  border: 1px solid #000;
  border-radius: 4px;
  margin-right: 15px;
  background-color: #fff;
}

.save button {
  width: 103px;
  height: 34px;
  background-color: #ff4300;
  border-radius: 4px;
  border: 1px solid #ff4300;
  margin-right: 10px;
  color: #fff;
}

.calenderActive {
  width: 35px;
  height: 35px;
  border-radius: 50%;
  background-color: #ff4300;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 10px;
  cursor: pointer;
}
.calenderInActive {
  width: 35px;
  height: 35px;
  border-radius: 50%;
  background-color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 10px;
  cursor: pointer;
}
