.modalHead p {
    font-size: 20px;
    font-weight: 400;
    font-family: Manrope;
    color: #ff4300;
    margin: 0;
  }
  .modalHead span {
    font-size: 20px;
    font-weight: 700;
    font-family: Manrope;
    color: #ff4300;
    margin: 0;
  }
  .modalBody p {
    font-size: 16px;
    font-weight: 400;
    font-family: Manrope;
    color: #212529;
    margin: 0;
  }
  .modalBody span {
    font-size: 16px;
    font-weight: 600;
    font-family: Manrope;
    color: #212529;
    margin: 0;
  }
  .btnSection {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }
  .close {
    width: 90px;
    height: 34px;
    border: 1px solid #000000;
    border-radius: 5px;
    background-color: #ffffff;
    font-size: 14px;
    font-weight: 500;
    font-family: Manrope;
    color: #000000;
  }
  .delete {
    width: 131px;
    height: 34px;
    border: 1px solid #ff4300;
    border-radius: 5px;
    background-color: #ff4300;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .delete p {
    font-family: Manrope;
    font-weight: 500;
    font-size: 14px;
    letter-spacing: -0.1px;
    color: #ffffff;
    margin: 0;
  }
  .delete span {
    font-family: Manrope;
    font-weight: 700;
    font-size: 14px;
    letter-spacing: -0.1px;
    color: #ffffff;
    margin: 0;
  }
  