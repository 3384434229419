.typeHeading {
  border-bottom: 1px solid #c1c1c1;
}
.typeHeading h4 {
  color: #000;
  font-size: 20px;
  font-weight: 600;
  letter-spacing: 0.46px;
  word-spacing: 4px;
}

.heading {
  font-size: 20px;
  font-weight: 600;
  letter-spacing: 0.46px;
  color: #000000;
  margin: 0;
  text-transform: uppercase;
}

.addWorkout {
  justify-content: space-between;
  padding: 15px 10px 15px 0px;
  position: relative;
  border-bottom: 1px solid #c1c1c1;
}

.inputIcon .FormInput,
.inputIcon select {
  padding: 0px 10px;
  height: 48px;
  font-size: 15px;
  width: 100%;
  max-width: 344px !important;
  border-radius: 4px;
  background-color: #fff !important;
  color: #000 !important;
  font-weight: 400;
  line-height: 1.6 !important;
  touch-action: manipulation;
  border: 1px solid #c1c1c1 !important;
}

.inputIcon .FormInput,
.inputIcon select:focus {
  outline: none;
  border: none;
  box-shadow: none;
}

.inputIcon .FormInput,
.inputIcon ::placeholder {
  color: #727272;
  width: 100%;
  border-radius: 8px;
}

.inputIcon label {
  font-size: 14px;
  font-weight: 500;
  color: #394858;
}

.labelText p {
  color: #000000;
  font-size: 16px;
  font-weight: 400;
  margin-bottom: 0px;
}

.labelText span {
  color: #000000;
  font-size: 16px;
  font-weight: 600;
}

.saveBtn button {
  width: 82px;
  height: 34px;
  color: #fff;
  font-size: 14px;
  font-weight: 500;
  border-radius: 4px;
  background-color: #ff4300;
  border: 1px solid #ff4300;
}

.saveBtn {
  position: absolute;
  top: 60px;
  left: 447px;
}

.typeList {
  margin-top: 20px;
  border: 1.5px solid #b5b5b5;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 0px 15px 15px;
  min-height: 52px;
  word-break: break-all;
  border-radius: 5px;
}

.typeList h3 {
  color: #000000;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0.28px;
  margin-bottom: 0px;
  word-break: break-word;
  width: 350px;
}

.editCross img {
  width: 24px;
  height: 24px;
  margin-right: 11px;
}

.addActivity {
  background: #ffffff;
  margin-top: 25px;
  padding: 13px 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 1px solid #b5b5b5;

  border-radius: 5px;
  align-items: center;
}
.activityCount {
  display: flex;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.addActivityBtn button {
  width: 120px;
  height: 34px;
  background-color: #ff4300;
  border: 1px solid #ff4300;
  border-radius: 4px;
}
.addActivityBtn p {
  font-size: 14px;
  font-weight: 400;
  color: #fff;
  margin: 0;
}
.addActivityBtn span {
  font-size: 14px;
  font-weight: 700;
  color: #fff;
  margin: 0;
}

.memberAdd p {
  color: #ff4300;
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 0px;
}

.memberAdd span {
  color: #000;
  font-weight: 600;
  font-size: 14px;
}

.addEDit {
  margin-top: 20px;
  border: 1px solid #c1c1c1;
  border-radius: 4px;
}

.workoutTYpes {
  background-color: #ececec;
  padding: 15px 20px 10px 15px;
  border-bottom: 1px solid #c1c1c1;
}

.workoutTYpes p {
  color: #394858;
  font-size: 16px;
  font-weight: 400;
  margin-bottom: 0px;
}

.workoutTYpes span {
  color: #394858;
  font-size: 16px;
  font-weight: 600;
}

.typeInput {
  background-color: #fff;
  /* padding: 0px 0px 0px 0px; */
}

.bottomBtn {
  display: flex;
  justify-content: space-between;
}

.bottomBtn button {
  width: 131px;
  height: 34px;
  border-radius: 4px;
  border: 1px solid #ff4300;
  background: #ff4300;
}
.bottomBtn button p {
  opacity: 1;
  color: rgba(255, 255, 255, 1);
  font-family: Manrope;
  font-size: 14px;
  font-weight: 400;
  margin: 0;
}
.bottomBtn button span {
  opacity: 1;
  color: rgba(255, 255, 255, 1);
  font-family: Manrope;
  font-size: 14px;
  font-weight: 700;
  margin: 0;
}
.bottomBtn p {
  opacity: 1;
  color: rgba(255, 67, 0, 1);
  font-family: Manrope;
  font-size: 14px;
  font-weight: 600;
  margin: 0;
}
.bottomBtn span {
  opacity: 1;
  color: #000000;
  font-family: Manrope;
  font-size: 14px;
  font-weight: 600;
  margin: 0;
}

.devider {
  width: 100%;
  height: 1px;
  background: #c1c1c1;
  margin: 20px 0px;
}

.noWork {
  margin-top: 10px;
}
.workHiglight {
  color: #ff4300;
}
