.prefillColor {
  height: 38px;
  width: 38px;
  border-radius: 4px;
  border: 1px solid #ced4da;
}

.exBtn {
  color: #fff;
  margin: 0;
}
.btnLabel p {
  color: #394858;
  font-size: 16px;
  font-weight: 400;
  font-family: Manrope;
  margin: 0;
}
.btnLabel span {
  color: #394858;
  font-size: 16px;
  font-weight: 600;
  font-family: Manrope;
}

.colorBtn button {
  width: 226px;
  height: 48px;
  border-radius: 4px;
  border: 1px solid #ffffff;
  background: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
}
.colorBtn button p {
  opacity: 1;
  color: rgba(255, 67, 0, 1);
  font-family: Manrope;
  font-size: 17px;
  font-weight: 900;
  letter-spacing: 1.48px;
  margin: 0;
}
.colorBtn button span {
  opacity: 1;
  color: rgba(255, 67, 0, 1);
  font-family: Manrope;
  font-size: 17px;
  font-weight: 300;
  letter-spacing: 1.48px;
}
.exPara {
  opacity: 1;
  color: rgba(255, 67, 0, 1);
  font-family: Manrope;
  font-size: 14px;
  font-weight: 600;
  margin: 5px 0px 0px 0px;
}
@media screen and (min-width: 320px) and (max-width: 575px) {
  .exBtn {
    color: #fff;
    margin: 10px 0px;
  }
}
