.heading p {
  opacity: 1;
  color: rgba(255, 67, 0, 1);
  font-family: Manrope;
  font-size: 20px;
  font-weight: 400;
  margin: 0;
}
.heading span {
  opacity: 1;
  color: rgba(255, 67, 0, 1);
  font-family: Manrope;
  font-size: 20px;
  font-weight: 600;
  margin: 0;
}
.colorPickerBox {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.SketchPicker {
  width: 93% !important;
  padding: 10px 10px 0px !important;
  box-sizing: initial;
  background: rgb(255, 255, 255);
  border-radius: 4px;
  box-shadow: none !important;
}
