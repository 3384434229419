/* -------------Member tab css -------------------*/

.plusIcon img {
  width: 35px;
  height: 35px;
}

.searchControl {
  border-radius: 4px;
  width: 100%;
  margin: 0px auto;
  border: 1px solid #c1c1c1;
  height: 38px;
  max-width: 183px;
}

.FormGroup .searchControl::placeholder {
  font-size: 14px;
  font-weight: 400;
  color: #c4c4c4;
}

.searchControl:focus {
  outline: none;
  border: 1px solid #c1c1c1;
}

.searchControl:focus {
  box-shadow: none !important;
  border: 1px solid #c1c1c1 !important;
}
.heading {
  font-size: 21px;
  font-weight: 600;
  line-height: 28.69px;
  letter-spacing: 0.47999998927116394px;
  text-align: left;
  color: #000000;
  margin: 0;
}
.devider {
  width: 100%;
  height: 1px;
  background: #c1c1c1;
  margin: 15px 0px;
}
.option {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.members {
  width: 110px;
  height: 40px;
  background: #000000;
  border-radius: 20px;
  border: 1px solid #000000;
  font-size: 14.4px;
  font-weight: 500;
  color: #ffffff;
}
.invites {
  width: 110px;
  height: 40px;
  background: #ffffff;
  border-radius: 20px;
  border: 1px solid #ffffff;
  font-size: 14.4px;
  font-weight: 500;
  color: #000000;
}
.search {
  width: 183px;
  height: 38px;
  background: #ffffff;
  border: 1px solid #c4c4c4;
  border-radius: 4px;
  padding: 0px 10px;
}
.summary {
  width: 100%;
  display: flex;
  justify-content: space-between;
}
.personalDetail {
  display: flex;
}
.personalDetailOff {
  font-size: 13px;
  font-weight: 400;
  line-height: 17.76px;
  color: #000000;
  margin: 0px 0px 0px 4px !important;
  cursor: pointer;
}
.personalDetailOn {
  font-size: 13px;
  font-weight: 700 !important;
  line-height: 17.76px;
  color: #000000;
  margin: 0px 0px 0px 4px !important;
  cursor: pointer;
}
.memberLimit {
  font-size: 13px;
  font-weight: 700;
  line-height: 17.76px;
  color: #000000;
}
.detail {
  display: flex;
  justify-content: space-between;
  background: #ffffff;
  border: 1px solid #cbcbcb;
  border-radius: 4px;
  padding: 15px 0px 15px 10px;
  margin-bottom: 20px;
}
.info {
  display: flex;
}
.infoOfMember {
  margin-left: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.name {
  font-size: 15px;
  font-weight: 600;
  line-height: 20.49px;
  color: #000000;
  margin-bottom: 0px;
}
.gender {
  font-size: 15px;
  font-weight: 300;
  line-height: 20.49px;
  color: #000000;
  margin-bottom: 0px;
}
.date {
  font-size: 12px;
  font-weight: 600;
  line-height: 16.39px;
  color: #ff4300;
  margin-top: 5px;
  margin-bottom: 0px;
}
.editCross {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  margin-right: 20px;
}
.inviteormore {
  background: #ffffff;
  margin-top: 25px;
  padding: 15px 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 1px solid #b5b5b5;
  border-radius: 5px;
  align-items: center;
}
.membersCount {
  display: flex;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.membersCount p {
  font-size: 14px;
  font-weight: 600;
  color: #ff4300;
  margin: 0;
}
.membersCount span {
  font-size: 14px;
  font-weight: 600;
  color: #000000;
  margin: 0;
}

.btnSection {
  display: flex;
  justify-content: space-between;
  width: 280px;
}

.memberLimitHead {
  font-size: 26px;
  font-weight: 700;
  line-height: 24px;
  color: #000000;
  margin-top: 20px;
}
.optionLabel {
  margin: 20px 0px 0px 0px;
  font-size: 16px;
  font-weight: 700;
  line-height: 21.86px;
  color: #394858;
}
.manageHead {
  margin: 10px 0px 0px 0px;
  font-size: 16px;
  font-weight: 400;
  line-height: 21.86px;
  color: #394858;
}
.manageSubscription {
  margin: 20px 0px 0px 0px;
  width: 275px;
  height: 48px;
  border-radius: 5px;
  background: #ff4300;
  border: 1px solid #ff4300;
  font-size: 17px;
  font-weight: 800;
  line-height: 20px;
  color: #ffffff;
}

.inviteBox {
  width: 100%;
  border-radius: 5px;
  background: #ffffff;
  padding: 20px;
}
.inviteBoxHead {
  font-size: 18px;
  font-weight: 600;
  line-height: 24.59px;
  color: #000000;
  border-bottom: 1px solid #c1c1c1;
}
.inviteInput {
  margin-top: 15px;
}

.inviteInput label {
  font-size: 14.5px;
  font-weight: 500;
  line-height: 19.81px;
  color: #555555;
}
.inviteInput input {
  width: 280px;
  height: 38px;
  border: 1px solid #c4c4c4;
  background: #f5f5f5;
}
.inviteInput::placeholder {
  font-size: 15px;
  font-weight: 400;
  line-height: 20.49px;
  color: #727272;
}
.inviteInput svg {
  vertical-align: top;
  margin-right: 6px;
  font-size: 16px;
  top: 8px;
  margin-top: 5px;
}
.note {
  margin-top: 8px;
}
.note p {
  font-size: 14px;
  font-weight: 500;
  line-height: 19.12px;
  color: #ff4300;
}
.note span {
  font-size: 14px;
  font-weight: 700;
  line-height: 19.12px;
  color: #ff4300;
}
.inviteBtn button {
  width: 280px;
  height: 41px;
  background: #ff4300;
  border-radius: 5px;
  border: 1px solid #ff4300;
}

.inviteBtn button p {
  font-size: 16px !important;
  font-weight: 400;
  line-height: 22.95px;
  letter-spacing: 0.48000001907348633px;
  color: #ffffff;
  margin-bottom: 0px;
}

.inviteBtn button span {
  font-size: 16.8px;
  font-weight: 600;
  line-height: 22.95px;
  letter-spacing: 0.48000001907348633px;
  color: #ffffff;
}

.notation {
  font-size: 16px;
  font-weight: 700;
  line-height: 21.86px;
  color: #394858;
}
.inviteDetail {
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 84px;
  background: #ffffff;
  border-radius: 5px;
  padding: 10px 20px;
  margin-bottom: 10px;
}
.inviteDetailInfo {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.inviteDetailInfo h1 {
  font-size: 15px;
  font-weight: 600;
  line-height: 20.49px;
  letter-spacing: 0.34285715222358704px;
  color: #000000;
  margin: 0;
}
.inviteDetailInfo p {
  font-size: 13px;
  font-weight: 400;
  line-height: 17.76px;
  letter-spacing: 0.2971428632736206px;
  color: #000000;
  margin: 0;
}
.inviteBtnSection {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 175px;
}
.inviteBtnSectionResend {
  width: 80px;
  height: 34px;
  background: #ffffff;
  border: 1px solid #000000;
  font-family: HelveticaNeue;
  font-size: 14px;
  line-height: 16.41px;
  letter-spacing: 0.4000000059604645px;
  color: #000000;
  border-radius: 5px;
}
.inviteBtnSectionCancel {
  width: 80px;
  height: 34px;
  background: #ff4300;

  border: 1px solid #ff4300;
  font-size: 14px;
  line-height: 16.41px;
  letter-spacing: 0.4000000059604645px;
  color: #ffffff;
  border-radius: 5px;
}
.headingAddMember {
  width: 100%;
  height: 58px;
  border-radius: 4px;
  background-color: #ff4300;
  display: flex;
  align-items: center;
  padding: 0px 20px;
}
.headingAddMember p {
  font-weight: 700;
  font-size: 15px;
  color: #ffffff;
  margin: 0;
}
.DetailHead {
  display: flex;
}
.DetailHead p {
  font-size: 20px;
  font-weight: 400;
  line-height: 24.59px;
  color: #000000;
  margin: 0;
}
.DetailHead h1 {
  font-size: 20px;
  font-weight: 700;
  line-height: 24.59px;
  color: #000000;
  margin: 0px 0px 0px 5px;
}
.DetailInput {
  margin: 0px 0px 5px 0px;
  position: relative;
}
.DetailInput label {
  font-size: 14.5px;
  font-weight: 500;
  line-height: 19.81px;
  color: #394858;
}
.DetailInput input {
  width: 280px;
  height: 38px;
  border: 1px solid #c4c4c4;
  background: #ffffff;
  border-radius: 5px;
}
.DetailInput input::placeholder {
  font-size: 15px;
  font-weight: 500;
  line-height: 24px;
  color: #6c757d;
}
.DetailInput select {
  height: 38px;
  width: 278px;
  border: 1px solid #c4c4c4;
  border-radius: 5px;
  background: linear-gradient(180deg, #ffffff 0%, #ececec 99.41%);
  font-size: 15px;
  color: #6c757d;
  font-weight: 500;
  line-height: 24px;
}

.DetailInput option {
  font-size: 15px;
  font-weight: 500;
  color: #6c757d;
}
.defaultOption {
  font-size: 15px;
  font-weight: 500;
  color: #6c757d;
}
.checkBoxArea {
  width: 280px;
  height: 38px;
  border: 1px solid #c4c4c4;
  border-radius: 5px;
  background: #ffffff;
  display: flex;
  padding: 0px 10px;
  align-items: center;
}
.checkBoxArea input {
  border-radius: 50%;
  width: 18px;
  height: 18px;
  border: 2px solid #828282;
  background: #f5f4f4;
}
.checkBoxArea label {
  font-size: 15px;
  font-weight: 400;
  line-height: 19.81px;
  color: #6c757d;
}

.profileSectionHead {
  font-size: 24px;
  font-weight: 500;
  line-height: 32.78px;
  color: #000000;
  margin: 0;
}
.profileSection {
  width: 100%;
  height: 190px;
  background: #ffffff;
  border: 1px solid #c4c4c4;
  border-radius: 5px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 20px;
  padding-right: 60px;
  margin-top: 15px;
  margin-bottom: 15px;
}
.profileSection img {
  width: 150px;
  height: 150px;
  border-radius: 50%;
}
.profileSectionOption {
  display: flex;
  flex-direction: column;
  height: 60%;
  justify-content: space-between;
}
.profileSectionOption button {
  width: 142px;
  height: 44px;
  background: #ff4300;
  border: 1px solid #ff4300;
  border-radius: 5px;
  font-size: 15px;
  font-weight: 600;
  line-height: 20.49px;
  color: #ffffff;
}
.profileSectionOption p {
  font-size: 14px;
  font-weight: 600;
  line-height: 19.12px;
  text-align: center;
  color: #767676;
  margin: 0;
}

.timeInput {
  display: flex;
  width: 250px;
  justify-content: space-between;
  align-items: center;
}
.timeInput input {
  width: 60px;
  height: 38px;
  border-radius: 5px;
  border: 1px solid #c4c4c4;
  background: #ffffff;
  padding: 0px 14px;
}
.timeInput p {
  color: #6c757d;
  margin: 0;
}
.checkboxGrp {
  display: flex;
}
.checkboxGrp .formcheckinput {
  width: 18px;
  height: 18px;
}
.switchCase {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.switchCaseInfo {
  display: flex;
  flex-direction: column;
}
.switchCaseInfo h1 {
  font-size: 16px;
  font-weight: 500;
  line-height: 21.86px;
  color: #000000;
}
.switchCaseInfo p {
  font-size: 14px;
  font-weight: 500;
  line-height: 19.12px;
  color: #828080;
  margin: 0;
}
.switchButton {
  font-size: 25px;
}
.cancelOrSave {
  width: 100%;
  height: 60px;
  background: #ffffff;
  border-radius: 5px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
}
.cancelBtn {
  width: 81px;
  height: 34px;
  border-radius: 5px;
  border: 1px solid #000000;
  font-size: 14px;
  font-weight: 500;
  color: #000000;
  background-color: #ffffff;
}
.saveBtn {
  width: 132px;
  height: 34px;
  border-radius: 5px;
  border: 1px solid #ff4300;
  font-size: 14px;
  font-weight: 500;
  color: #ffffff;
  background-color: #ff4300;
}

/* -------------Member tab css end -------------------*/

.heading {
  font-family: Manrope;
  font-size: 20px;
  font-weight: 600;
  line-height: 28.69px;
  letter-spacing: 0.46px;
  text-align: left;
  color: #000000;
  margin: 0;
}
.devider {
  width: 100%;
  height: 1px;
  background: #c1c1c1;
  margin: 15px 0px 15px 0px;
}
.option {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.members {
  width: 88px;
  height: 35px;
  background: #000000;
  border-radius: 18px;
  border: 1px solid #000000;
  font-family: Manrope;
  font-size: 14.4px;
  font-weight: 500;
  color: #ffffff;
}
.invites {
  width: 88px;
  height: 35px;
  background: #ffffff;
  border-radius: 18px;
  border: 1px solid #ffffff;
  font-family: Manrope;
  font-size: 14.4px;
  font-weight: 500;
  color: #000000;
}
.search {
  width: 183px;
  height: 38px;
  margin-top: 0px;
  background: #ffffff;
  border: 1px solid #c4c4c4;
  border-radius: 4px;
  padding: 0px 10px;
  font-family: Helvetica Neue;
  font-weight: 400;
  font-size: 15px;
  color: #6c757d;
}
.search:focus {
  outline: none;
  box-shadow: none;
}

.personalDetail {
  display: flex;
}
.personalDetail p {
  font-family: Manrope;
  font-size: 13px;
  font-weight: 400;
  line-height: 17.76px;
  letter-spacing: 0.26px;
  color: #000000;
  margin: 0;
}
.personalDetail h1 {
  font-family: Manrope;
  font-size: 13px;
  font-weight: 700;
  line-height: 17.76px;
  letter-spacing: 0.26px;
  color: #000000;
  margin: 0px 4px;
}
.memberLimit {
  font-family: Manrope;
  font-size: 13px;
  font-weight: 700;
  line-height: 17.76px;
  letter-spacing: 0.26px;
  color: #000000;
  margin-top: 0px;
}
.detail {
  min-height: 94px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #ffffff;
  border: 1px solid #cbcbcb;
  border-radius: 4px;
  padding: 5px 0px 5px 6px;
  margin-bottom: 20px;
}
.info {
  display: flex;
}
.info img {
  width: 75px;
  height: 75px;
  border-radius: 50%;
  object-fit: cover;
  cursor: pointer;
}
.infoOfMember {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left:15px;
  margin-top: 5px;
  margin-bottom: 5px;
}
.name {
  font-family: Manrope;
  font-size: 15px;
  font-weight: 600;
  line-height: 20.49px;
  letter-spacing: 0.3px;
  color: #000000;
  margin: 0;
  text-transform: capitalize;
  word-break: break-word;
}
.gender {
  font-family: Manrope;
  font-size: 15px;
  font-weight: 300;
  line-height: 20.49px;
  letter-spacing: 0.3px;
  color: #000000;
  margin: 0;
}
.date {
  font-family: Manrope;
  font-size: 12px;
  font-weight: 600;
  line-height: 16.39px;
  letter-spacing: 0.3px;
  color: #ff4300;
  margin: 0;
}
.editCross {
  height: 60px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.editCross img {
  width: 24px;
  height: 24px;
}
.inviteormore {
  width: 100%;
  height: 60px;
  background: #ffffff;
  margin-top: 25px;
  padding: 10px;
  display: flex;
  justify-content: space-between;
  border: 1px solid #b5b5b5;
  border-radius: 0px 0px 5px 5px;
  align-items: center;
}
.membersCount {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.membersCount p {
  font-family: Manrope;
  font-size: 14px;
  font-weight: 600;
  color: #ff4300;
  margin: 0;
}
.membersCount span {
  font-family: Manrope;
  font-size: 14px;
  font-weight: 600;
  color: #000000;
  margin: 0;
}

.btnSection {
  display: flex;
  justify-content: space-between;
  width: 280px;
}
.membersCount button {
  height: 34px;
  padding: 0px 20px;
}
.membersCount button p {
  font-family: Manrope;
  font-weight: 400;
  font-size: 14px;
  letter-spacing: 0.4px;
  color: #ffffff;
  margin: 0;
}
.membersCount button span {
  font-family: Manrope;
  font-weight: 700;
  font-size: 14px;
  letter-spacing: 0.4px;
  color: #ffffff;
  margin: 0;
}
.memberLimitHead {
  font-family: Manrope;
  font-size: 26px;
  font-weight: 700;
  line-height: 24px;
  color: #000000;
  margin-top: 20px;
}
.optionLabel {
  margin: 20px 0px 0px 0px;
  font-family: Manrope;
  font-size: 16px;
  font-weight: 700;
  line-height: 21.86px;
  color: #394858;
}
.manageHead {
  margin: 10px 0px 0px 0px;
  font-family: Manrope;
  font-size: 16px;
  font-weight: 400;
  line-height: 21.86px;
  color: #394858;
}
.manageSubscription {
  margin: 20px 0px 0px 0px;
  width: 275px;
  height: 48px;
  border-radius: 4px;
  background: #ff4300;
  border: 1px solid #ff4300;
  display: flex;
  justify-content: center;
  align-items: center;
}
.manageSubscription p {
  font-family: Manrope;
  font-weight: 900;
  font-size: 17px;
  letter-spacing: 1.48px;
  line-height: 20px;
  color: #ffffff;
  margin: 0;
}
.manageSubscription span {
  font-family: Manrope;
  font-weight: 500;
  font-size: 17px;
  letter-spacing: 1.48px;
  line-height: 20px;
  color: #ffffff;
}
.inviteBox {
  width: 100%;
  border-radius: 5px;
  background: #ffffff;
  padding: 15px 15px;
  margin-top: 20px;
}
.inviteBoxHead p {
  font-family: Manrope;
  font-size: 22px;
  font-weight: 400;
  line-height: 24.59px;
  color: #000000;
}

.inviteBoxHead span {
  font-family: Manrope;
  font-size: 22px;
  font-weight: 600;
  line-height: 24.59px;
  color: #000000;
}
.inviteInput {
  margin-top: 15px;
}

.inviteInput label {
  font-family: Manrope;
  font-size: 14.5px;
  font-weight: 500;
  line-height: 19.81px;
  color: #555555;
  margin-bottom: 3px;
}
.inviteInput input {
  width: 280px;
  height: 38px;
  border: 1px solid #c4c4c4;
  background: #f5f5f5;
}
.inviteInput::placeholder {
  font-family: Manrope;
  font-size: 15px;
  font-weight: 400;
  line-height: 20.49px;
  color: #727272;
}
/* .inviteInput svg {
  vertical-align: top;
  margin-right: 10px;
  width: 24px;
  height: 24px;
} */

.note p {
  font-family: Manrope;
  font-size: 14px;
  font-weight: 500;
  line-height: 19.12px;
  color: #ff4300;
}
.note span {
  font-family: Manrope;
  font-size: 14px;
  font-weight: 700;
  line-height: 19.12px;
  color: #ff4300;
}
.inviteBtn {
  width: 280px;
  height: 41px;
  background: #ff4300;
  border-radius: 5px;
  border: 1px solid #ff4300;
  font-family: Manrope;
  font-size: 16.8px;
  font-weight: 400;
  line-height: 22.95px;
  letter-spacing: 0.48000001907348633px;
  color: #ffffff;
  margin-top: 20px;
  display: flex;
  justify-content: center;
}
.notation {
  font-family: Manrope;
  font-size: 16px;
  font-weight: 700;
  line-height: 21.86px;
  color: #394858;
}
.inviteDetail {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  height: 84px;
  background: #ffffff;
  border-radius: 5px;
  padding: 10px 20px;
  margin-bottom: 10px;
}
.inviteDetailInfo {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.inviteDetailInfo h1 {
  font-family: Manrope;
  font-size: 15px;
  font-weight: 600;
  line-height: 20.49px;
  letter-spacing: 0.34285715222358704px;
  color: #000000;
  margin: 0;
}
.inviteDetailInfo p {
  font-family: Manrope;
  font-size: 13px;
  font-weight: 400;
  line-height: 17.76px;
  letter-spacing: 0.2971428632736206px;
  color: #000000;
  margin: 0;
}
.inviteBtnSection {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 175px;
  margin-top: 0px;
}
.inviteBtnSectionResend {
  width: 80px;
  height: 34px;
  background: #ffffff;
  border: 1px solid #000000;
  font-family: HelveticaNeue;
  font-size: 14px;
  line-height: 16.41px;
  letter-spacing: 0.4000000059604645px;
  color: #000000;
  border-radius: 5px;
}
.inviteBtnSectionCancel {
  width: 80px;
  height: 34px;
  background: #ff4300;

  border: 1px solid #ff4300;
  font-family: HelveticaNeue;
  font-size: 14px;
  line-height: 16.41px;
  letter-spacing: 0.4000000059604645px;
  color: #ffffff;
  border-radius: 5px;
}
.headingAddMember {
  width: 100%;
  height: 58px;
  border-radius: 4px;
  background-color: #ff4300;
  display: flex;
  align-items: center;
  padding: 0px 20px;
}
.headingAddMember p {
  font-family: Manrope;
  font-weight: 700;
  font-size: 15px;
  color: #ffffff;
  margin: 0;
}
.DetailHead {
  display: flex;
}
.DetailHead p {
  font-family: Manrope;
  font-size: 20px;
  font-weight: 400;
  line-height: 24.59px;
  color: #000000;
  margin: 0;
}
.DetailHead h1 {
  font-family: Manrope;
  font-size: 20px;
  font-weight: 700;
  line-height: 24.59px;
  color: #000000;
  margin: 0px 0px 0px 5px;
}
.DetailInput {
  margin: 0px 0px 5px 0px;
  position: relative;
}
.DetailInput label {
  font-family: Manrope;
  font-size: 14.5px;
  font-weight: 500;
  line-height: 19.81px;
  color: #394858;
}
.DetailInput input {
  width: 280px;
  height: 38px;
  border: 1px solid #c4c4c4;
  background: #ffffff;
  border-radius: 5px;
}
.DetailInput input::placeholder {
  font-family: Manrope;
  font-size: 15px;
  font-weight: 500;
  line-height: 24px;
  color: #6c757d;
}
.DetailInput select {
  font-family: Manrope;
  height: 38px;
  width: 278px;
  border: 1px solid #c4c4c4;
  border-radius: 5px;
  background: linear-gradient(180deg, #ffffff 0%, #ececec 99.41%);
  font-size: 15px;
  color: #6c757d;
  font-weight: 500;
  line-height: 24px;
}

.DetailInput option {
  font-family: Manrope;
  font-size: 15px;
  font-weight: 500;
  color: #6c757d;
}
.defaultOption {
  font-family: Manrope;
  font-size: 15px;
  font-weight: 500;
  color: #6c757d;
}
.checkBoxArea {
  width: 280px;
  height: 38px;
  border: 1px solid #c4c4c4;
  border-radius: 5px;
  background: #ffffff;
  display: flex;
  padding: 0px 10px;
  align-items: center;
}
.checkBoxArea input {
  border-radius: 50%;
  width: 18px;
  height: 18px;
  border: 2px solid #828282;
  background: #f5f4f4;
}
.checkBoxArea label {
  font-family: Manrope;
  font-size: 15px;
  font-weight: 400;
  line-height: 19.81px;
  color: #6c757d;
}

.profileSectionHead {
  font-family: Manrope;
  font-size: 24px;
  font-weight: 500;
  line-height: 32.78px;
  color: #000000;
  margin: 0;
}
.profileSection {
  width: 100%;
  height: 190px;
  background: #ffffff;
  border: 1px solid #c4c4c4;
  border-radius: 5px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 20px;
  padding-right: 60px;
  margin-top: 15px;
  margin-bottom: 15px;
}
.profileSection img {
  width: 150px;
  height: 150px;
  border-radius: 50%;
}
.profileSectionOption {
  display: flex;
  flex-direction: column;
  height: 60%;
  justify-content: space-between;
}
.profileSectionOption button {
  width: 142px;
  height: 44px;
  background: #ff4300;
  border: 1px solid #ff4300;
  border-radius: 5px;
  font-family: Manrope;
  font-size: 15px;
  font-weight: 600;
  line-height: 20.49px;
  color: #ffffff;
}
.profileSectionOption p {
  font-family: Manrope;
  font-size: 14px;
  font-weight: 600;
  line-height: 19.12px;
  text-align: center;
  color: #767676;
  margin: 0;
}

.timeInput {
  display: flex;
  width: 250px;
  justify-content: space-between;
  align-items: center;
  margin-left: -33px;
  margin-right: 90px;
}
.timeInput input {
  width: 60px;
  height: 38px;
  border-radius: 5px;
  border: 1px solid #c4c4c4;
  background: #ffffff;
  padding: 0px 14px;
}
.timeInput p {
  color: #6c757d;
  margin: 0;
}
.checkboxGrp {
  display: flex;
}
.checkboxGrp .formcheckinput {
  width: 18px;
  height: 18px;
}
.switchCase {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.switchCaseInfo {
  display: flex;
  flex-direction: column;
}
.switchCaseInfo h1 {
  font-family: Manrope;
  font-size: 16px;
  font-weight: 500;
  line-height: 21.86px;
  color: #000000;
}
.switchCaseInfo p {
  font-family: Manrope;
  font-size: 14px;
  font-weight: 500;
  line-height: 19.12px;
  color: #828080;
  margin: 0;
}
.switchButton {
  font-size: 25px;
}
.cancelOrSave {
  width: 100%;
  height: 60px;
  background: #ffffff;
  border-radius: 5px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
}
.cancelBtn {
  width: 81px;
  height: 34px;
  border-radius: 5px;
  border: 1px solid #000000;
  font-size: 14px;
  font-weight: 500;
  font-family: Manrope;
  color: #000000;
  background-color: #ffffff;
}
.saveBtn {
  width: 132px;
  height: 34px;
  border-radius: 5px;
  border: 1px solid #ff4300;
  font-size: 14px;
  font-weight: 500;
  font-family: Manrope;
  color: #ffffff;
  background-color: #ff4300;
}

@media screen and (min-width: 320px) and (max-width: 575px) {
  .searchControl {
    margin: 0px 0px;
  }
  .formGroup {
    margin-top: 20px;
  }

  .option {
    width: 100%;
    display: block;
  }

  .search {
    width: 100%;
    height: 38px;
    margin-top: 15px;
    background: #ffffff;
    border: 1px solid #c4c4c4;
    border-radius: 4px;
    padding: 0px 10px;
    font-family: Helvetica Neue;
    font-weight: 400;
    font-size: 15px;
    color: #6c757d;
  }
  .search:focus {
    outline: none;
    box-shadow: none;
  }
  .summary {
    width: 100%;
    display: block;
    justify-content: space-between;
  }
  .memberLimit {
    margin-top: 10px;
  }
  .inviteormore {
    height: auto;
  }
  .membersCount {
    flex-direction: column;
    height: 105px;
  }
  .inviteDetail {
    flex-direction: column;
    height: auto;
  }
  .inviteBtnSection {
    margin-top: 10px;
  }

  .crosIconSerch svg {
    position: absolute;
    top: 45px;
    right: 19px !important;
  }
}

@media screen and (min-width: 575px) and (max-width: 767px) {
  .crosIconSerch svg {
    position: absolute;
    top: 45px;
    right: 210px !important;
  }
}

/*----------- members details css -------------*/
.topHeadM h2 {
  color: #000000;
  font-size: 21px;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 0.48px;
}

.topHead h2 {
  color: #000;
  font-size: 20px;
  font-weight: 600;
  border-bottom: 1px solid #c1c1c1;
  /* padding: 15px 0px; */
  padding-bottom: 15px;
  text-transform: uppercase;
}
.borderBox {
  border-bottom: 1px solid #c1c1c1;
}

.detailBox {
  padding: 5px 0px 5px 0px;
  background-color: #404040;
  border: 1px solid #404040;
  margin-top: 15px;
  margin-bottom: 15px;
  border-radius: 4px;
}

.topContent img {
  width: 100%;
  max-width: 46px;
  height: 46px;
  margin-right: 10px;
  border-radius: 50%;
  object-fit: cover;
}

.topContent {
  display: flex;
  padding: 0px 10px 0px 10px;
}

.topContent p {
  color: #ffffff;
  font-size: 15px;
  font-weight: 600;
  margin-bottom: 0px;
  margin-top: 11px;
}

.topContent span {
  color: #ffffff;
  font-size: 16px;
  font-weight: 700;
}

.bottomContent {
  margin-top: 20px;
  border-bottom: 1px solid #c1c1c1;
  padding: 15px 0px 15px 0px;
  border-top: 1px solid #c1c1c1;
}
.memberData {
  padding: 10px 0px 0px 0px;
}
.memberData p {
  color: #000000;
  font-size: 22px;
  font-weight: 400;
}

.memberData span {
  color: #000000;
  font-size: 22px;
  font-weight: 700;
}
.memberDataEdit p {
  color: #000000 !important;
  font-size: 22px !important;
  font-weight: 400 !important;
}

.memberDataEdit span {
  color: #000000 !important;
  font-size: 22px !important;
  font-weight: 700 !important;
}

.memberDetails p {
  color: #394858;
  font-size: 13px;
  font-weight: 500;
  margin-bottom: 3px;
}
.memberDetails h5 {
  color: #000000;
  font-size: 16px;
  font-weight: 500;
}
.profilePic {
  padding: 10px 0px 10px 0px;
  /* border-bottom: 1px solid #c1c1c1; */
  border-top: 1px solid #c1c1c1;
}

.profilePic p {
  color: #000000;
  font-size: 22px;
  font-weight: 400;
}

.profilePic span {
  color: #000000;
  font-size: 22px;
  font-weight: 700;
}

.profilePic img {
  border-radius: 50%;
  height: 150px;
  object-fit: contain;
  width: 150px;
}

.personalBestBox {
  width: 320px;
  max-width: 100%;
  height: 38px;
  border-radius: 4px;
  border: 1px solid #979797;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 18px;
}
.personalBestBox p {
  font-family: Manrope;
  font-size: 16px;
  font-weight: 700;
  color: #000000;
  margin: 0;
}
.personalBestBox span {
  font-family: Manrope;
  font-size: 16px;
  font-weight: 300;
  color: #000000;
  margin: 0;
}

.detailSubHeading {
  margin-bottom: 20px;
}
.detailSubHeading p {
  font-size: 22px;
  font-weight: 400;
  font-family: Manrope;
  color: #000000;
  line-height: 30px;
  margin: 0;
}
.detailSubHeading span {
  font-size: 22px;
  font-weight: 700;
  font-family: Manrope;
  color: #000000;
  line-height: 30px;
  margin: 0;
}

.inputBox {
  margin-top: 12px;
}

.inputBox {
  border: 1px solid #c1c1c1;
  background-color: #f5f4f4;
  padding: 10px 0px 10px 10px;
  width: 350px;
}

.inputBox p {
  color: #000;
  font-size: 15px;
  font-weight: 700;
  margin-bottom: 0px;
}

.inputBox span {
  color: #000;
  font-size: 15px;
  font-weight: 400;
}
.memberShip {
  padding: 10px 0px 15px 0px;
  border-bottom: 1px solid #c1c1c1;
}

.memberShip p {
  color: #000000;
  font-size: 22px;
  font-size: 400;
}

.memberShip span {
  color: #000000;
  font-size: 22px;
  font-size: 700;
}

.editBtn {
  background-color: #fff;
  border-radius: 5px;
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
  border: 1px solid #c1c1c1;
  height: 60px;
}

.editDetail {
  /* padding: 10px 10px; */
  padding: 13px 10px 0px 0px;
}

.editDetail button {
  padding: 0px 30px;
  height: 34px;
}

/*-------- edit form input css ------------*/
.editForm {
  padding: 0px 0px 5px 0px;
  /* border-bottom: 1px solid #c1c1c1; */
}
.inputIcon .FormInput,
.inputIcon select {
  padding: 0px 10px;
  height: 48px;
  font-size: 15px;
  width: 100%;
  max-width: 350px;
  border-radius: 4px;
  background-color: #fff !important;
  color: #000 !important;
  font-weight: 400;
  line-height: 1.6 !important;
  touch-action: manipulation;
  border: 1px solid #c1c1c1 !important;
}

.inputIcon .FormInput,
.inputIcon select:focus {
  outline: none;
  border: none;
  box-shadow: none;
}

.inputIcon .FormInput,
.inputIcon ::placeholder {
  color: #727272;
  width: 100%;
  border-radius: 4px;
}

.inputIcon label {
  font-size: 14px;
  font-weight: 500;
  color: #394858;
  margin-top: 5px;
  margin-bottom: 5px;
}
.weightSelect label {
  font-size: 14px;
  font-weight: 500;
  color: #394858;
  margin-top: 10px;
  margin-bottom: 5px;
}
.shareCheck {
  padding: 5px 0px 0px 0px;
}

.selectDate {
  padding: 10px 0px;
}

.selectDate p {
  color: #394858;
  font-size: 14.5px;
  font-weight: 500;
  margin-bottom: 0px;
}

.genderBox h2 {
  color: #394858;
  font-size: 14.5px;
  font-weight: 500;
}

.genderBorder {
  border: 1px solid #c1c1c1;
  background-color: #fff;
  padding: 8px 0px 8px 10px;
  width: 100%;
  max-width: 350px;
}

.genderBorder label {
  margin-right: 12px;
  font-size: 15px;
  font-weight: 400;
  color: #394858;
}

.genderBorder input {
  margin-right: 10px;
}
.CountrylabelText {
  color: #394858;
  font-size: 14px;
  font-weight: 500;
}

.profileHeading {
  padding: 10px 0px 10px 0px;
}

.profileHeading h4 {
  font-size: 24px;
  font-weight: 500;
  color: #000000;
}

.profileSection {
  width: 100%;
  height: 190px;
  background: #ffffff;
  border: 1px solid #c4c4c4;
  border-radius: 5px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 20px;
  padding-right: 60px;
  margin-top: 15px;
  margin-bottom: 15px;
}
.profileSection img {
  width: 150px;
  height: 150px;
  border-radius: 50%;
}
.profileSectionOption {
  display: flex;
  flex-direction: column;
  height: 45%;
  justify-content: space-between;
}
/* .profileSectionOption button {
  width: 142px;
  height: 44px;
  background: #ff4300;
  border: 1px solid #ff4300;
  border-radius: 5px;
  font-family: Manrope;
  font-size: 15px;
  font-weight: 600;
  line-height: 20.49px;
  color: #ffffff;
} */
.profileSectionOption p {
  font-family: Manrope;
  font-size: 14px;
  font-weight: 600;
  line-height: 19.12px;
  text-align: center;
  color: #767676;
  margin: 0;
}

.personalBestSection {
  /* padding: 10px 0px 10px 0px; */
  /* border-bottom: 1px solid #c1c1c1; */
  /* border-top: 1px solid #c1c1c1; */
}

.personalBestSection h2 {
  color: #000000;
  font-size: 24px;
  font-weight: 500;
}

.inputElement {
  display: flex;
  padding: 10px 0px 10px 0px;
  justify-content: space-between;
}

.kmHeading h3 {
  /* margin-right: 70px; */
  color: #394858;
  font-size: 20px;
  font-weight: 700;
  margin-top: 10px;
}
.inputArea input:focus {
  outline: none;
  border: 1px solid #c1c1c1;
  box-shadow: none;
}
.inputArea {
  margin-left: 12px;
}
.inputArea label {
  color: #394858;
  font-size: 11px;
  font-weight: 500;
  margin-left: 10px;
}
.timeInput span {
  color: #394858;
  /* width: 30px; */
  margin-top: -27px;
  margin-left: 10px;
  font-size: 24px;
  font-weight: 600;
}

.loginDetail {
  /* border-bottom: 1px solid #c1c1c1; */
  padding: 0px 0px 23px 0px;
}

.headingSection {
  border-top: 1px solid #c1c1c1;
  border-bottom: 1px solid #c1c1c1;
  margin-top: 18px;
}
.headingSection p {
  color: #000000;
  font-size: 22px;
  font-weight: 500;
  margin-bottom: 0px;
  padding: 10px 0px 10px 0px;
}

.headingSection span {
  color: #000000;
  font-size: 22px;
  font-weight: 700;
}

.statusHeading {
  /* border-bottom: 1px solid #c1c1c1; */
  border-top: 1px solid #c1c1c1;
}

.statusHeading p {
  color: #000000;
  font-size: 22px;
  font-weight: 500;
  margin-bottom: 0px;
  padding: 15px 0px 0px 0px;
}
.statusHeading span {
  color: #000000;
  font-size: 22px;
  font-weight: 700;
}

.switchOn h3 {
  font-size: 16px;
  font-weight: 600;
  color: #000000;
  margin-bottom: 0px;
}

.switchOn {
  display: flex;
  justify-content: space-between;
  margin-top: 15px;
}

.memeberActive {
  border-bottom: 1px solid #c1c1c1;
}
.memeberActive p {
  color: #000000;
  font-size: 14px;
  font-weight: 500;
}

.addBrand {
  border-top: 1px solid #000;
}

.addBrand p {
  color: #000000;
  font-size: 22px;
  font-weight: 500;
  padding: 10px 0px 10px 0px;
  margin-bottom: 0px;
  border-bottom: 1px solid #c1c1c1;
}
.addBrand span {
  color: #000000;
  font-size: 22px;
  font-weight: 700;
}

.addBrandText p {
  color: #000000;
  font-size: 22px;
  font-weight: 500;
  margin-bottom: 0px;
}
.addBrandText span {
  color: #000000;
  font-size: 22px;
  font-weight: 700;
}

.selectBrand .labelText {
  color: #394858;
  font-size: 14.5px;
  font-weight: 500;
  padding: 10px 0px 0px 0px;
  text-transform: capitalize;
}

.brandInput {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #c1c1c1;
  padding: 0px 0px 10px 0px;
}

.addBtn button {
  text-align: center;
  padding: 0px 15px;
  background-color: #ff4300;
  border-radius: 7px;
  width: 100%;
  height: 34px;
  color: #fff;
  border: 1px solid #ff4300;
  font-size: 17px;
  font-weight: 600;
  margin-top: 46px;
}

.membershipInfo {
  border: 1px solid #c1c1c1;
  /* padding: 10px 0px 10px 0px; */
  margin-top: 20px;
}
.subInfo {
  background-color: #000;
  padding: 15px 0px 15px 10px;
  color: #fff;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}
.subInfo h3 {
  color: #fff;
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 0px;
  word-break: break-word;
}

.validShip {
  padding: 10px 0px 10px 10px;
  background-color: #fff;
}

.validShip p {
  color: #394858;
  font-size: 16px;
  font-weight: 400;
  margin-bottom: 3px;
}

.activeMembership {
  /* border-bottom: 1px solid #c1c1c1; */
  /* border-top: 1px solid #c1c1c1; */
  width: 100%;
  max-width: 480px;
}
.activeMembership p {
  color: #000000;
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 10px;
}

.otherMemberShip {
  border-bottom: 1px solid #c1c1c1;
  width: 100%;
  max-width: 480px;
}

.otherMemberShip p {
  color: #000000;
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 10px;
}

.removeBrand {
  background-color: #ececec;
}

.removeBTn {
  display: flex;
  justify-content: flex-end;
  padding: 10px 0px 10px 0px;
}

.removeBTn button {
  padding: 10px 35px;
  height: 42px;
  background-color: #ff4300;
  border-radius: 4px;
  border: 1px solid #ff4300;
  margin-right: 10px;
}

.removeBTn button p {
  text-align: center;
  color: #fff;
  font-size: 12px;
  font-weight: 400;
  text-transform: uppercase;
  margin-bottom: 0px;
}

/* .removeBTn button span {
  text-align: center;
  color: #fff;
  font-size: 12px;
  font-weight: 700;
  text-transform: uppercase;
} */

.eventBtn {
  display: flex;
  justify-content: space-between;
  padding: 10px 10px 10px 10px;
}
.cancel button {
  width: 81px;
  height: 34px;
  font-size: 14px;
  font-weight: 400;
  border: 1px solid #000;
  border-radius: 4px;
}

.save button {
  width: 103px;
  height: 34px;
  background-color: #ff4300;
  border-radius: 4px;
  border: 1px solid #ff4300;
  margin-right: 10px;
  color: #fff;
}

.bottomContent p {
  color: #394858;
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 0px;
}

/*========= Delete modal css =========*/

.modalHead p {
  color: #000000;
  font-size: 18px;
  font-weight: 500;
  margin-bottom: 0px;
  text-align: center;
}

.modalHead span {
  color: #000000;
  font-size: 18px;
  font-weight: 700;
}

.modalBody p {
  color: #000000;
  font-size: 17px;
  font-weight: 400;
  text-align: center;
  margin-bottom: 0px;
}

.modalBody span {
  color: #000000;
  font-size: 17px;
  font-weight: 900;
}
.btnFooter {
  display: flex !important;
  justify-content: center !important;
}

.deleteBtn button {
  width: 300px;
  height: 54px;
  background: #ff4300;
  font-size: 17.5px;
  font-weight: 600;
  border-radius: 5px;
  border: 1px solid #ff4300;
  color: #ffffff;
}

.addBtnEdit button {
  height: 34px;
}

.noRecordFound p {
  font-family: Manrope;
  font-weight: 700;
  font-size: 15px;
  line-height: 23px;
  color: #212529;
  margin: 0;
}
.noRecordFound span {
  font-family: Manrope;
  font-weight: 500;
  font-size: 15px;
  line-height: 23px;
  color: #212529;
  margin: 0;
}

.NaBox p {
  color: #000;
  font-size: 20px;
  font-weight: 600;
}

.DetailInput {
  margin: 8px 0px 5px 0px;
  position: relative;
}
/* .DetailInput label {
  font-family: Manrope;
  font-size: 14.5px;
  font-weight: 500;
  line-height: 19.81px;
  color: #000000;
} */
.DetailInput input {
  width: 350px;
  max-width: 100%;
  height: 38px;
  border: 1px solid #c4c4c4;
  background: #ffffff;
  border-radius: 5px;
}

.DetailInput input:focus {
  outline: none;
  box-shadow: none;
  border: 1px solid #c1c1c1;
}

.DetailInput input::placeholder {
  font-family: Manrope;
  font-size: 15px;
  font-weight: 500;
  line-height: 24px;
  color: #6c757d;
}
.DetailInput select {
  font-family: Manrope;
  height: 38px;
  min-width: 350px;
  border: 1px solid #c4c4c4;
  border-radius: 5px;
  background: linear-gradient(180deg, #ffffff 0%, #ececec 99.41%);
  font-size: 15px;
  color: #6c757d;
  font-weight: 500;
  line-height: 24px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.DetailInput p {
  position: absolute;
  font-family: Manrope;
  font-weight: 500;
  font-size: 15px;
  line-height: 24px;
  color: #000000;
  z-index: 1;
  margin: 0;
  top: 36px;
  left: 35px;
}

.selectImg {
  height: 9px;
  width: 15px;
  position: absolute;
  left: 320px;
  top: 54px;
}

.selectImgCountry {
  height: 9px;
  width: 15px;
  position: absolute;
  left: 310px;
  top: 46px;
}

/* distance css */

.timeGrid {
  /* width: 342px; */
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.timeHead {
  margin-top: 15px;
  margin-bottom: 15px;
}
.timeHead span {
  font-family: Manrope;
  font-size: 20px;
  font-weight: 700;
  line-height: 21.86px;
  color: #394858;
}
.timeHead p {
  font-family: Manrope;
  font-size: 20px;
  font-weight: 700;
  line-height: 21.86px;
  color: #394858;
}
.timeInput {
  display: flex;
  width: 237px;
  justify-content: space-between;
  align-items: center;
}
.timeInput input {
  width: 60px;
  height: 38px;
  border-radius: 4px;
  border: 1px solid #c4c4c4;
  background: #ffffff;
  /* padding: 0px 14px; */
  text-align: center;
}
.timeInput p {
  color: #6c757d;
  margin: 0;
}

.personalBestTimeLabel {
  font-family: Manrope;
  font-weight: 500;
  font-size: 11px;
  line-height: 24px;
  color: #000000 !important;
  text-align: center;
}

.checkBoxArea {
  width: 350px;
  max-width: 100%;
  height: 38px;
  border: 1px solid #c4c4c4;
  border-radius: 5px;
  background: #ffffff;
  display: flex;
  padding: 0px 10px;
  align-items: center;
}
.checkBoxArea input {
  border-radius: 50%;
  width: 18px;
  height: 18px;
  border: 2px solid #828282;
  background: #f5f4f4;
}
.checkBoxArea label {
  font-family: Manrope;
  font-size: 15px;
  font-weight: 400;
  line-height: 19.81px;
  color: #6c757d;
}

.cancelOrSave {
  width: 100%;
  height: 60px;
  background: #ffffff;
  border-radius: 5px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  margin-top: 20px;
}
.cancelBtn {
  width: 81px;
  height: 34px;
  border-radius: 5px;
  border: 1px solid #000000;
  font-size: 14px;
  font-weight: 500;
  font-family: Manrope;
  color: #000000;
  background-color: #ffffff;
  margin-right: 10px;
}
.saveBtn {
  width: 132px;
  height: 34px;
  border-radius: 5px;
  border: 1px solid #ff4300;
  font-size: 14px;
  font-weight: 500;
  font-family: Manrope;
  color: #ffffff;
  background-color: #ff4300;
}
.saveBtn:hover {
  border: 1px solid #fa6833 !important;
  background-color: #fa6833 !important; /* Custom hover color */
}

.headingAddMember {
  width: 100%;
  height: 58px;
  border-radius: 4px;
  background-color: #ff4300;
  display: flex;
  align-items: center;
  padding: 0px 20px;
}
.headingAddMember p {
  font-family: Manrope;
  font-weight: 700;
  font-size: 15px;
  color: #ffffff;
  margin: 0;
}

.detailInfo {
  display: flex;
  flex-direction: column;
  margin-bottom: 15px;
}
.detailInfo p {
  font-family: Manrope;
  font-size: 13px;
  font-weight: 500;
  color: #394858;
  line-height: 18px;
  margin: 0;
}
.detailInfo h1 {
  font-family: Manrope;
  font-size: 16px;
  font-weight: 500;
  color: #000000;
  line-height: 24px;
  margin: 0;
}

.switchCase {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.switchCaseInfo {
  display: flex;
  flex-direction: column;
}
.switchCaseInfo h1 {
  font-family: Manrope;
  font-size: 16px;
  font-weight: 500;
  line-height: 21.86px;
  color: #000000;
  margin-bottom: 0px;
}
.switchCaseInfo p {
  font-family: Manrope;
  font-size: 14px;
  font-weight: 500;
  line-height: 19.12px;
  color: #828080;
  margin: 0;
}

.addDevider {
  width: 100%;
  height: 1px;
  background: #c1c1c1;
  margin: 10px 0px 0px 0px;
}

.dobInput input {
  width: 350px;
  max-width: 100%;
  height: 38px;
  border: 1px solid #c4c4c4;
  background: #ffffff;
  border-radius: 5px;
  padding: 0px 40px;
}
.dobInput input::placeholder {
  font-family: Manrope;
  font-size: 15px;
  font-weight: 500;
  line-height: 24px;
  color: #6c757d;
}

.dobInput label {
  font-size: 14.5px;
  font-weight: 500;
  line-height: 19.81px;
  color: #394858;
  margin-top: 9px;
}

.dobInput input:focus {
  outline: none;
  box-shadow: none;
  border: 1px solid #c1c1c1;
}

.validInput input {
  width: 220px;
  max-width: 100%;
  height: 38px;
  border: 1px solid #c4c4c4;
  background: #c4c4c466;
  border-radius: 5px;
  padding: 0px 40px;
}

.validInput input:focus {
  outline: none;
  box-shadow: none;
  border: 1px solid #c1c1c1;
  background: #c4c4c466;
}

.validInput input::placeholder {
  font-family: Manrope;
  font-size: 15px;
  font-weight: 500;
  line-height: 24px;
  color: #000000;
}

.validInput label {
  font-size: 14.5px;
  font-weight: 500;
  line-height: 19.81px;
  color: #394858;
  margin-top: 9px;
}

.datePickerBox {
  position: relative;
  margin-bottom: 10px;
}
.datePickerBox p {
  font-family: Manrope;
  font-size: 14.5px;
  font-weight: 500;
  line-height: 19.81px;
  color: #000000;
  margin: 0px 0px 8px 0px;
}
.datePickerBox svg {
  position: absolute;
  left: 5px;
  top: 8px;
}
.dateSelection {
  width: 350px;
  height: 38px;
  border-radius: 4px;
  background-color: #f6f6f6;
  border: 1px solid #c4c4c4;
  display: flex;
  align-items: center;
  padding: 0px 10px;
  cursor: pointer;
}
.dateSelection img {
  height: 25px;
  width: 24px;
}
.dateSelection p {
  opacity: 1;
  color: #6c757d !important;
  font-family: Manrope;
  font-size: 14px;
  font-weight: 600;
  margin: 0px 0px 0px 10px;
}

.searchBox {
  position: relative;
  width: 100%;
  border-radius: 4px;
  border: 1px solid #ffffff;
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 13px 6px 13px 0px;
}
.searchBox p {
  font-family: Manrope;
  font-weight: 500;
  font-size: 14.5px;
  color: #394858;
  margin: 0;
  line-height: 18px;
  text-transform: uppercase;
}

.searchBox input {
  width: 449px;
  height: 58px;
  border-radius: 4px;
  border: 1px solid #c4c4c4;
  background-color: #f5f5f5;
  font-family: Manrope;
  font-weight: 600;
  font-size: 15px;
  /* color: #000000; */
  color: rgba(108, 117, 125, 1);
  padding: 0px 70px;
  margin-top: 10px;
}
.searchBox input::placeholder {
  font-family: Manrope;
  font-weight: 600;
  font-size: 15px;
  /* color: #000000; */
  color: rgba(108, 117, 125, 1);
}
.profleImg img {
  position: absolute;
  width: 46px;
  height: 46px;
  top: 47px;
  left: 10px;
}
.searchBox input:focus {
  border: 1px solid #c4c4c4;
  outline: none;
  box-shadow: none;
}
.searchResult {
  width: 466px;
  height: 58px;
  border-radius: 4px;
  border: 1px solid #404040;
  background: #404040;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 13px;
  padding: 0px 7px;
}
.searchInfo {
  display: flex;
  justify-content: space-between;
}
.searchInfo img {
  height: 46px;
  width: 46px;
  border-radius: 50%;
  object-fit: cover;
}

.userDetail {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  margin: 5px 10px;
}
.userDetail h1 {
  font-family: Manrope;
  font-weight: 600;
  font-size: 15px;
  /* color: #ffffff; */
  margin: 0;
}
.userDetail p {
  font-family: Manrope;
  font-weight: 400;
  font-size: 10px;
  /* color: #ffffff; */
  margin: 0;
}

.crosIconSerch svg {
  position: absolute;
  top: 45px;
  right: 59px;
}

.selectBrand {
  position: relative;
}
.dateSelectionBox {
  width: 220px;
  height: 38px;
  border: 1px solid #c4c4c4;
  border-radius: 4px;
  background: #efefef;
  display: flex;
  align-items: center;
  padding: 0px 5px;
}
.dateSelectionBox img {
  height: 25px;
  width: 24px;
}
.dateSelectionBox p {
  opacity: 1;
  color: rgba(0, 0, 0, 1);
  font-family: Manrope;
  font-size: 15px;
  font-weight: 500;
  margin: 0px 0px 0px 10px;
}

.phoneSelectImg {
  cursor: pointer;
  z-index: 6;
  pointer-events: none !important;
  position: absolute;
  left: 96px;
  top: 16px;

}

.clubNameCheck{
  display: block;
}